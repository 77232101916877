
/**** Action icons - Circle action icons ****/
.vismaicon-menu-circle {
    &:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/24_menu.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/24_menu.svg');
    }
    &.vismaicon-sm:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/16_menu.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/16_menu.svg');
    }
}

.vismaicon-add-circle {
    &:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/24_add.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/24_add.svg');
    }
    &.vismaicon-sm:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/16_add.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/16_add.svg');
    }
}

.vismaicon-remove-circle {
    &:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/24_remove.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/24_remove.svg');
    }
    &.vismaicon-sm:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/16_remove.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/16_remove.svg');
    }
}

.vismaicon-ok-circle {
    &:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/24_ok.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/24_ok.svg');
    }
    &.vismaicon-sm:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/16_ok.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/16_ok.svg');
    }
}

.vismaicon-cancel-circle {
    &:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/24_cancel.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/24_cancel.svg');
    }
    &.vismaicon-sm:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/16_cancel.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/16_cancel.svg');
    }
}

.vismaicon-arrow-up-circle {
    &:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/24_arrow_up.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/24_arrow_up.svg');
    }
    &.vismaicon-sm:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/16_arrow_up.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/16_arrow_up.svg');
    }
}

.vismaicon-arrow-down-circle {
    &:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/24_arrow_down.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/24_arrow_down.svg');
    }
    &.vismaicon-sm:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/16_arrow_down.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/16_arrow_down.svg');
    }
}

.vismaicon-arrow-left-circle {
    &:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/24_arrow_left.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/24_arrow_left.svg');
    }
    &.vismaicon-sm:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/16_arrow_left.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/16_arrow_left.svg');
    }
}

.vismaicon-arrow-right-circle {
    &:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/24_arrow_right.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/24_arrow_right.svg');
    }
    &.vismaicon-sm:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/16_arrow_right.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/16_arrow_right.svg');
    }
}

.vismaicon-expand-circle {
    &:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/24_expand.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/24_expand.svg');
    }
    &.vismaicon-sm:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/16_expand.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/16_expand.svg');
    }
}

.vismaicon-collapse-circle {
    &:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/24_collapse.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/24_collapse.svg');
    }
    &.vismaicon-sm:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/16_collapse.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/16_collapse.svg');
    }
}

.vismaicon-other-circle {
    &:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/24_other.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/24_other.svg');
    }
    &.vismaicon-sm:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/16_other.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/16_other.svg');
    }
}

.vismaicon-forward-circle {
    &:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/24_forward.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/24_forward.svg');
    }
    &.vismaicon-sm:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/16_forward.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/16_forward.svg');
    }
}

.vismaicon-rewind-circle {
    &:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/24_rewind.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/24_rewind.svg');
    }
    &.vismaicon-sm:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/16_rewind.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/16_rewind.svg');
    }
}

.vismaicon-pause-circle {
    &:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/24_pause.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/24_pause.svg');
    }
    &.vismaicon-sm:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/16_pause.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/16_pause.svg');
    }
}

.vismaicon-play-circle {
    &:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/24_play.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/24_play.svg');
    }
    &.vismaicon-sm:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/16_play.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/16_play.svg');
    }
}

.vismaicon-first-circle {
    &:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/24_first.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/24_first.svg');
    }
    &.vismaicon-sm:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/16_first.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/16_first.svg');
    }
}

.vismaicon-last-circle {
    &:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/24_last.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/24_last.svg');
    }
    &.vismaicon-sm:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/16_last.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/16_last.svg');
    }
}

.vismaicon-move-up-circle {
    &:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/24_up.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/24_up.svg');
    }
    &.vismaicon-sm:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/16_up.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/16_up.svg');
    }
}

.vismaicon-move-down-circle {
    &:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/24_down.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/24_down.svg');
    }
    &.vismaicon-sm:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/16_down.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/16_down.svg');
    }
}

.vismaicon-move-left-circle {
    &:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/24_left.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/24_left.svg');
    }
    &.vismaicon-sm:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/16_left.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/16_left.svg');
    }
}

.vismaicon-move-right-circle {
    &:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/24_right.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/24_right.svg');
    }
    &.vismaicon-sm:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/16_right.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/16_right.svg');
    }
}

.vismaicon-forbidden-circle {
    &:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/24_forbidden.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/24_forbidden.svg');
    }
    &.vismaicon-sm:before {
        -webkit-mask-image: url('#{$vud-icons-path}/action-icons/24_forbidden.svg');
        mask-image: url('#{$vud-icons-path}/action-icons/24_forbidden.svg'); 
    }
}

// Core variables and mixins
//# Default Variables

//### Main font-size
// base/html font size
$vud-units: 'rem' !default;
$html-font-size: 62.5% !default; // --> 1rem = 10px

//### Return only the intiger/number value (strip the unit type)
@use "sass:math";

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
      @return math.div($number, $number * 0 + 1); 
  }
  @return $number; 
}



// REM function
// used: rem(18) or rem(18px),
// return the rem (if $vud-units is set as rem, or in px if it set as px) value
// depending on the '$html-font-size' value set.
@function rem($value, $units: $vud-units) {
  $result: '';
  $basesize: 10;

  // definding the base font size of the html/browser from '$html-font-size'.
  @if $html-font-size {
    @if unit($html-font-size) == "%" {
      $basesize: 16 * strip-unit($html-font-size) * 0.01;
    } @else if unit($html-font-size) == "px" { 
      $basesize: strip-unit($html-font-size);
    }
  }

  // result defind by the '$vud-units' unit type.
  @if $units == 'rem' {
    $result: math.div(strip-unit($value), $basesize) * 1rem;
  } @else if $units == 'px' {
    $result: strip-unit($value) * 1px;
  } @else {
    @warn "The $vud-units takes only 'px' or 'rem' as value option.";
  }

  @return $result;
}



/* Setup a few global scope properties */
:root, :host {
  //=== 1. Core CSS Variables
  //# a. Color palette
  --vloc-white: #ffffff;
  --vloc-black: #000000;


  //### Purple palette
  --vloc-purple-90: #403380;
  --vloc-purple-80: #4d3d99;
  --vloc-purple-70: #6154a5;
  --vloc-purple-60: #7365b8;
  --vloc-purple-50: #8a80bd;
  --vloc-purple-40: #9e96c8;
  --vloc-purple-30: #b2abd3;
  --vloc-purple-20: #c6c1df;
  --vloc-purple-10: #dad7ea;
  --vloc-purple-05: #edecf5;

  --vloc-primary-purple: #4d3d99; // $purple-80


  //### Blue palette
  --vloc-blue-90: #1b384c;
  --vloc-blue-80: #14476a;
  --vloc-blue-70: #0d5788;
  --vloc-blue-60: #116fae; 
  --vloc-blue-50: #1482cc;
  --vloc-blue-40: #419bd7;
  --vloc-blue-30: #6cb2e1;
  --vloc-blue-20: #99cbec;
  --vloc-blue-10: #d2eafa;
  --vloc-blue-05: #e7f2f9;

  --vloc-primary-blue: #116fae; // $blue-60


  //### Green palette
  --vloc-green-90: #254934;
  --vloc-green-80: #316245;
  --vloc-green-70: #2d7048;
  --vloc-green-60: #4e9c54;
  --vloc-green-50: #67a870;
  --vloc-green-40: #7faf7f;
  --vloc-green-30: #9bc29b;
  --vloc-green-20: #afd4af;
  --vloc-green-10: #cfe7cf;
  --vloc-green-05: #ebf7eb;

  --vloc-primary-green: #2d7048; // $green-70


  //### Red palette
  --vloc-red-90: #801f30;
  --vloc-red-80: #a12036;
  --vloc-red-70: #cc334c;
  --vloc-red-60: #d44f64;
  --vloc-red-50: #db6b7d;
  --vloc-red-40: #e28594;
  --vloc-red-30: #eaa1ac;
  --vloc-red-20: #f1bdc4;
  --vloc-red-10: #fad9de;
  --vloc-red-05: #ffedef;

  --vloc-primary-red: #cc334c; // $red-70;


  //### Orange palette
  --vloc-orange-90: #803b00;
  --vloc-orange-80: #b35300;
  --vloc-orange-70: #c26800;
  --vloc-orange-60: #CA7900;
  --vloc-orange-50: #e5a23e;
  --vloc-orange-40: #edb45f;
  --vloc-orange-30: #f2c480;
  --vloc-orange-20: #fad7a2;
  --vloc-orange-10: #fcdfb1;
  --vloc-orange-05: #fcf2dc;

  --vloc-primary-orange: #803b00; // $orange-90
}



// Light and Dark CSS Variables
//
// 2. Light mode CSS Variables
@mixin light-mode {
  //===  Color palette
  //# Neutral palette
  --vloc-neutral-90: #252626;
  --vloc-neutral-80: #494a4a;
  --vloc-neutral-70: #6b6b6b;
  --vloc-neutral-60: #8a8a8a;
  --vloc-neutral-50: #a5a5a5;
  --vloc-neutral-40: #c0c0c0;
  --vloc-neutral-30: #dadada;
  --vloc-neutral-20: #f2f2f2;
  --vloc-neutral-10: #f7f7f7;
  --vloc-neutral-05: #ffffff;

  --vloc-primary-neutral: #252626; // $neutral-90;

  //===  Icons
  // worksourface, action and social media icons
  --icon-bg: var(--custom-icon-bg, var(--vloc-blue-50));
  --icon-hover-bg: var(--custom-icon-hover-bg, var(--vloc-blue-40));
  --icon-disabled-bg: var(--custom-icon-disabled-bg, var(var--vloc-neutral-60));

  // Informative icons
  --icon-informative-success-bg: var(--custom-icon-informative-success-bg, var(--vloc-green-60));
  --icon-informative-error-bg: var(--custom-icon-informative-error-bg, var(--vloc-red-70));
  --icon-informative-warning-bg: var(--custom-icon-informative-warning-bg, var(--vloc-orange-70));
  --icon-informative-info-bg: var(--custom-icon-informative-info-bg, var(--vloc-blue-50));
  --icon-informative-help-bg: var(--custom-icon-informative-help-bg, var(--vloc-blue-50));

  // Carets and close icons
  --caret-default-bg: var(--custom-caret-default-bg, var(--vloc-neutral-90));
  --caret-primary-bg: var(--custom-caret-primary-bg, var(--vloc-blue-50));
  --caret-secondary-bg: var(--custom-caret-secondary-bg, var(--vloc-neutral-05));
  --caret-disabled-bg: var(--custom-caret-disabled-bg, var(--vloc-neutral-60));
  --caret-navigation-bg: var(--custom-caret-navigation-bg, var(--vloc-neutral-05));
  --close-icon-bg: var(--custom-close-icon-bg, var(--vloc-neutral-80));
  --close-focus-outline: var(--custom-close-focus-outline, var(--vloc-blue-50));

  // primary button
  --button-primary-icon: var(--custom-button-primary-icon, var(--vloc-neutral-05));
}




//
// 3. Dark mode CSS Variables
@mixin dark-mode {
  //===  Color palette
  //# Neutral palette
  --vloc-neutral-90: #000000;
  --vloc-neutral-80: #15181a;
  --vloc-neutral-70: #2b3033;
  --vloc-neutral-60: #4c545b;
  --vloc-neutral-50: #6f7a82;
  --vloc-neutral-40: #86929b;
  --vloc-neutral-30: #9aa5ae;
  --vloc-neutral-20: #afbcc4;
  --vloc-neutral-10: #c3d0d9;
  --vloc-neutral-05: #e4ebf0;

  --vloc-primary-neutral: #2b3033; // $neutral-70;


  //===  Icons
  // worksourface, action and social media icons
  --icon-bg: var(--custom-dark-icon-bg, var(--vloc-blue-30));
  --icon-hover-bg: var(--custom-dark-icon-hover-bg, var(--vloc-blue-40));
  --icon-disabled-bg: var(--custom-dark-icon-disabled-bg, var(--vloc-neutral-40));

  // Informative icons
  --icon-informative-success-bg: var(--custom-dark-icon-informative-success-bg, var(--vloc-green-60));
  --icon-informative-error-bg: var(--custom-dark-icon-informative-error-bg, var(--vloc-red-60));
  --icon-informative-warning-bg: var(--custom-dark-icon-informative-warning-bg, var(--vloc-orange-70));
  --icon-informative-info-bg: var(--custom-dark-icon-informative-info-bg, var(--vloc-blue-50));
  --icon-informative-help-bg: var(--custom-dark-icon-informative-help-bg, var(--vloc-blue-50));


  // Carets and close icons
  --caret-default-bg: var(--custom-dark-caret-default-bg, var(--vloc-neutral-20));
  --caret-primary-bg: var(--custom-dark-caret-primary-bg, var(--vloc-blue-30));
  --caret-secondary-bg: var(--custom-dark-caret-secondary-bg, var(--vloc-neutral-05));
  --caret-disabled-bg: var(--custom-dark-caret-disabled-bg, var(--vloc-neutral-40));
  --caret-navigation-bg: var(--custom-dark-caret-navigation-bg, var(--vloc-neutral-20));
  --close-icon-bg: var(--custom-dark-close-icon-bg, var(--vloc-neutral-20));
  --close-focus-outline: var(--custom-dark-close-focus-outline, var(--vloc-blue-50));

  // primary button
  --button-primary-icon: var(--custom-dark-button-primary-icon, var(--vloc-neutral-05));
}


//# Pictograms
//=== Pictogram monocrome 64x64px
.visma-pictogram {
    // pictogram list:
    &.visma-pictogram-add-organisation {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_add_organisation.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_add_organisation.svg');
        }
    }

    &.visma-pictogram-added-organisation {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_added_organisation.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_added_organisation.svg');
        }
    }

    &.visma-pictogram-admin {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_admin.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_admin.svg');
        }
    }

    &.visma-pictogram-air-balloon {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_air_balloon.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_air_balloon.svg');
        }
    }

    &.visma-pictogram-alarm {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_alarm.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_alarm.svg');
        }
    }

    &.visma-pictogram-apple-waste {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_apple_waste.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_apple_waste.svg');
        }
    }

    &.visma-pictogram-baby {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_baby.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_baby.svg');
        }
    }

    &.visma-pictogram-baby-2 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_baby_2.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_baby_2.svg');
        }
    }

    &.visma-pictogram-balloons {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_balloons.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_balloons.svg');
        }
    }

    &.visma-pictogram-bank {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_bank.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_bank.svg');
        }
    }

    &.visma-pictogram-bicycle {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_bicycle.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_bicycle.svg');
        }
    }

    &.visma-pictogram-bike-ski {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_bike_ski.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_bike_ski.svg');
        }
    }

    &.visma-pictogram-book {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_book.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_book.svg');
        }
    }

    &.visma-pictogram-book-note {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_book_note.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_book_note.svg');
        }
    }

    &.visma-pictogram-bread {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_bread.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_bread.svg');
        }
    }

    &.visma-pictogram-bronze_rank {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_bronze_rank.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_bronze_rank.svg');
        }
    }

    &.visma-pictogram-bronze_rank-2 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_bronze_rank_2.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_bronze_rank_2.svg');
        }
    }

    &.visma-pictogram-bronze_rank-3 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_bronze_rank_3.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_bronze_rank_3.svg');
        }
    }

    &.visma-pictogram-budget {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_budget.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_budget.svg');
        }
    }

    &.visma-pictogram-budget-2 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_budget_2.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_budget_2.svg');
        }
    }

    &.visma-pictogram-budget-3 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_budget_3.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_budget_3.svg');
        }
    }

    &.visma-pictogram-budget-4 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_budget_4.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_budget_4.svg');
        }
    }

    &.visma-pictogram-building {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_building.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_building.svg');
        }
    }

    &.visma-pictogram-bulb {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_bulb.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_bulb.svg');
        }
    }

    &.visma-pictogram-bulb-2 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_bulb_2.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_bulb_2.svg');
        }
    }

    &.visma-pictogram-bulb-3 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_bulb_3.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_bulb_3.svg');
        }
    }

    &.visma-pictogram-buyer {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_buyer.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_buyer.svg');
        }
    }

    &.visma-pictogram-buyer-2 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_buyer_2.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_buyer_2.svg');
        }
    }

    &.visma-pictogram-buyer-3 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_buyer_3.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_buyer_3.svg');
        }
    }

    &.visma-pictogram-calendar {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_calendar.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_calendar.svg');
        }
    }

    &.visma-pictogram-calendar-2 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_calendar_2.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_calendar_2.svg');
        }
    }

    &.visma-pictogram-calendar-3 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_calendar_3.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_calendar_3.svg');
        }
    }

    &.visma-pictogram-calendar-search {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_calendar_search.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_calendar_search.svg');
        }
    }

    &.visma-pictogram-car {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_car.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_car.svg');
        }
    }

    &.visma-pictogram-case-system {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_case_system.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_case_system.svg');
        }
    }

    &.visma-pictogram-check-mark {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_check_mark.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_check_mark.svg');
        }
    }

    &.visma-pictogram-checklist {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_checklist.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_checklist.svg');
        }
    }

    &.visma-pictogram-checkmark-edit {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_checkmark_edit.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_checkmark_edit.svg');
        }
    }

    &.visma-pictogram-clipboard {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_clipboard.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_clipboard.svg');
        }
    }

    &.visma-pictogram-clock {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_clock.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_clock.svg');
        }
    }

    &.visma-pictogram-clock-2 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_clock_2.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_clock_2.svg');
        }
    }

    &.visma-pictogram-clock-3 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_clock_3.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_clock_3.svg');
        }
    }

    &.visma-pictogram-cloud-security {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_cloud_security.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_cloud_security.svg');
        }
    }

    &.visma-pictogram-co2-emissions {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_co2_emissions.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_co2_emissions.svg');
        }
    }

    &.visma-pictogram-code-browser {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_code_browser.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_code_browser.svg');
        }
    }

    &.visma-pictogram-code-language {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_code_language.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_code_language.svg');
        }
    }

    &.visma-pictogram-coffee-break {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_coffee_break.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_coffee_break.svg');
        }
    }

    &.visma-pictogram-coffee-break-2 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_coffee_break_2.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_coffee_break_2.svg');
        }
    }

    &.visma-pictogram-coffee-can {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_coffee_can.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_coffee_can.svg');
        }
    }

    &.visma-pictogram-collaboration {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_collaboration.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_collaboration.svg');
        }
    }

    &.visma-pictogram-community-chat {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_community_chat.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_community_chat.svg');
        }
    }

    &.visma-pictogram-computer-hardware {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_computer_hardware.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_computer_hardware.svg');
        }
    }

    &.visma-pictogram-contract {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_contract.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_contract.svg');
        }
    }

    &.visma-pictogram-contract-coin {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_contract_coin.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_contract_coin.svg');
        }
    }

    &.visma-pictogram-contract-signed {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_contract_signed.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_contract_signed.svg');
        }
    }

    &.visma-pictogram-creative {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_creative.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_creative.svg');
        }
    }

    &.visma-pictogram-credit-card {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_credit_card.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_credit_card.svg');
        }
    }

    &.visma-pictogram-customer-reports {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_customer_reports.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_customer_reports.svg');
        }
    }

    &.visma-pictogram-customer-reports-2 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_customer_reports_2.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_customer_reports_2.svg');
        }
    }

    &.visma-pictogram-dark-hacker {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_dark_hacker.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_dark_hacker.svg');
        }
    }

    &.visma-pictogram-data-analytics {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_data_analytics.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_data_analytics.svg');
        }
    }

    &.visma-pictogram-data-collect {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_data_collect.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_data_collect.svg');
        }
    }

    &.visma-pictogram-data-dashboard {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_data_dashboard.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_data_dashboard.svg');
        }
    }

    &.visma-pictogram-data-protection {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_data_protection.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_data_protection.svg');
        }
    }

    &.visma-pictogram-documents {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_documents.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_documents.svg');
        }
    }

    &.visma-pictogram-edit-content {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_edit_content.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_edit_content.svg');
        }
    }

    &.visma-pictogram-empty-folder {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_empty_folder.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_empty_folder.svg');
        }
    }

    &.visma-pictogram-file-card {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_file_card.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_file_card.svg');
        }
    }

    &.visma-pictogram-file-coins {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_file_coins.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_file_coins.svg');
        }
    }

    &.visma-pictogram-file-configuration {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_file_configuration.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_file_configuration.svg');
        }
    }

    &.visma-pictogram-file-drag {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_file_drag.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_file_drag.svg');
        }
    }

    &.visma-pictogram-file-heart {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_file_heart.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_file_heart.svg');
        }
    }

    &.visma-pictogram-file-restore {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_file_restore.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_file_restore.svg');
        }
    }

    &.visma-pictogram-file-server {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_file_server.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_file_server.svg');
        }
    }

    &.visma-pictogram-file-settings {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_file_settings.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_file_settings.svg');
        }
    }

    &.visma-pictogram-files-sent {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_files_sent.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_files_sent.svg');
        }
    }

    &.visma-pictogram-files-settings {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_files_settings.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_files_settings.svg');
        }
    }

    &.visma-pictogram-files-undo {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_files_undo.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_files_undo.svg');
        }
    }

    &.visma-pictogram-firework {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_firework.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_firework.svg');
        }
    }

    &.visma-pictogram-flying-paper-plane {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_flying_paper_plane.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_flying_paper_plane.svg');
        }
    }

    &.visma-pictogram-folder-files {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_folder_files.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_folder_files.svg');
        }
    }

    &.visma-pictogram-folder-setting {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_folder_setting.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_folder_setting.svg');
        }
    }

    &.visma-pictogram-folder-setting-2 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_folder_setting_2.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_folder_setting_2.svg');
        }
    }

    &.visma-pictogram-folder-user {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_folder_user.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_folder_user.svg');
        }
    }

    &.visma-pictogram-food-drinks {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_food_and_drinks.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_food_and_drinks.svg');
        }
    }

    &.visma-pictogram-food-drinks-2 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_food_and_drinks_2.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_food_and_drinks_2.svg');
        }
    }

    &.visma-pictogram-gamepad-joystick {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_gamepad_joystick.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_gamepad_joystick.svg');
        }
    }

    &.visma-pictogram-garage {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_garage.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_garage.svg');
        }
    }

    &.visma-pictogram-global {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_global.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_global.svg');
        }
    }

    &.visma-pictogram-global-locations {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_global_locations.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_global_locations.svg');
        }
    }

    &.visma-pictogram-global-settings {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_global_settings.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_global_settings.svg');
        }
    }

    &.visma-pictogram-gold-rank {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_gold_rank.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_gold_rank.svg');
        }
    }

    &.visma-pictogram-gold-rank-2 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_gold_rank_2.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_gold_rank_2.svg');
        }
    }

    &.visma-pictogram-gold-rank-3 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_gold_rank_3.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_gold_rank_3.svg');
        }
    }

    &.visma-pictogram-graduation {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_graduation.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_graduation.svg');
        }
    }

    &.visma-pictogram-graph-charts-settings {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_graph_charts_settings.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_graph_charts_settings.svg');
        }
    }

    &.visma-pictogram-graph-grow {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_graph_grow.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_graph_grow.svg');
        }
    }

    &.visma-pictogram-growth {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_growth.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_growth.svg');
        }
    }

    &.visma-pictogram-growth-2 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_growth_2.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_growth_2.svg');
        }
    }

    &.visma-pictogram-hacker {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_hacker.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_hacker.svg');
        }
    }

    &.visma-pictogram-happy-file {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_happy_file.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_happy_file.svg');
        }
    }

    &.visma-pictogram-home {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_home.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_home.svg');
        }
    }

    &.visma-pictogram-hr {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_hr.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_hr.svg');
        }
    }

    &.visma-pictogram-hr-2 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_hr_2.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_hr_2.svg');
        }
    }

    &.visma-pictogram-image {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_image.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_image.svg');
        }
    }

    &.visma-pictogram-indoor-temperature {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_indoor_temperature.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_indoor_temperature.svg');
        }
    }

    &.visma-pictogram-invoice-cards {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_invoice_cards.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_invoice_cards.svg');
        }
    }

    &.visma-pictogram-invoice-confirmation {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_invoice_confirmation.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_invoice_confirmation.svg');
        }
    }

    &.visma-pictogram-invoice-declined {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_invoice_declined.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_invoice_declined.svg');
        }
    }

    &.visma-pictogram-invoice-declined-2 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_invoice_declined_2.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_invoice_declined_2.svg');
        }
    }

    &.visma-pictogram-invoice-edit {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_invoice_edit.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_invoice_edit.svg');
        }
    }

    &.visma-pictogram-invoice-search-euro {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_invoice_search_euro.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_invoice_search_euro.svg');
        }
    }

    &.visma-pictogram-invoice-selected {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_invoice_selected.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_invoice_selected.svg');
        }
    }

    &.visma-pictogram-invoice-selected-2 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_invoice_selected_2.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_invoice_selected_2.svg');
        }
    }

    &.visma-pictogram-invoice-sent {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_invoice_sent.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_invoice_sent.svg');
        }
    }

    &.visma-pictogram-juice-can {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_juice_can.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_juice_can.svg');
        }
    }

    &.visma-pictogram-keypad-panel {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_keypad_panel.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_keypad_panel.svg');
        }
    }

    &.visma-pictogram-keypad-panel-2 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_keypad_panel_2.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_keypad_panel_2.svg');
        }
    }

    &.visma-pictogram-lamp {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_lamp.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_lamp.svg');
        }
    }

    &.visma-pictogram-laptop-checkmark {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_laptop_checkmark.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_laptop_checkmark.svg');
        }
    }

    &.visma-pictogram-laptop-code {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_laptop_code.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_laptop_code.svg');
        }
    }

    &.visma-pictogram-laptop-cursor {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_laptop_cursor.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_laptop_cursor.svg');
        }
    }

    &.visma-pictogram-laptop-graph {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_laptop_graph.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_laptop_graph.svg');
        }
    }

    &.visma-pictogram-leaf {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_leaf.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_leaf.svg');
        }
    }

    &.visma-pictogram-leaning-book {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_leaning_book.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_leaning_book.svg');
        }
    }

    &.visma-pictogram-libra {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_libra.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_libra.svg');
        }
    }

    &.visma-pictogram-like {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_like.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_like.svg');
        }
    }

    &.visma-pictogram-like-2 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_like_2.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_like_2.svg');
        }
    }

    &.visma-pictogram-like-3 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_like_3.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_like_3.svg');
        }
    }

    &.visma-pictogram-location {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_location.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_location.svg');
        }
    }

    &.visma-pictogram-lock {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_lock.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_lock.svg');
        }
    }

    &.visma-pictogram-locked-file {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_locked_file.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_locked_file.svg');
        }
    }

    &.visma-pictogram-log {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_log.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_log.svg');
        }
    }

    &.visma-pictogram-mail-information {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_mail_information.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_mail_information.svg');
        }
    }

    &.visma-pictogram-mail-invoice {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_mail_invoice.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_mail_invoice.svg');
        }
    }

    &.visma-pictogram-mail-invoice-electricity-bill {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_mail_invoice_electricity_bill.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_mail_invoice_electricity_bill.svg');
        }
    }

    &.visma-pictogram-mail-invoice-euro {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_mail_invoice_euro.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_mail_invoice_euro.svg');
        }
    }

    &.visma-pictogram-mail-sent {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_mail_sent.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_mail_sent.svg');
        }
    }

    &.visma-pictogram-megaphone {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_megaphone.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_megaphone.svg');
        }
    }

    &.visma-pictogram-mobile-phone {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_mobile_phone.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_mobile_phone.svg');
        }
    }

    &.visma-pictogram-mobile-tap {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_mobile_tap.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_mobile_tap.svg');
        }
    }

    &.visma-pictogram-needle {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_needle.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_needle.svg');
        }
    }

    &.visma-pictogram-office-building {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_office_building.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_office_building.svg');
        }
    }

    &.visma-pictogram-office-keys {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_office_keys.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_office_keys.svg');
        }
    }

    &.visma-pictogram-organisation {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_organisation.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_organisation.svg');
        }
    }

    &.visma-pictogram-organisation-2 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_organisation_2.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_organisation_2.svg');
        }
    }

    &.visma-pictogram-organisation-accepted {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_organisation_accepted.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_organisation_accepted.svg');
        }
    }

    &.visma-pictogram-organisation-added {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_organisation_added.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_organisation_added.svg');
        }
    }

    &.visma-pictogram-organisation_building {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_organisation_building.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_organisation_building.svg');
        }
    }

    &.visma-pictogram-organisation_building-2 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_organisation_building_2.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_organisation_building_2.svg');
        }
    }

    &.visma-pictogram-organisation-settings {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_organisation_settings.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_organisation_settings.svg');
        }
    }

    &.visma-pictogram-organisation-structure {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_organisation_structure.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_organisation_structure.svg');
        }
    }

    &.visma-pictogram-paper-plane {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_paper_plane.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_paper_plane.svg');
        }
    }

    &.visma-pictogram-paper-plane-2 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_paper_plane_2.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_paper_plane_2.svg');
        }
    }

    &.visma-pictogram-payment {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_payment.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_payment.svg');
        }
    }

    &.visma-pictogram-payment-received {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_payment_received.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_payment_received.svg');
        }
    }

    &.visma-pictogram-personal-time {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_personal_time.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_personal_time.svg');
        }
    }

    &.visma-pictogram-phone-accepted {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_phone_accepted.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_phone_accepted.svg');
        }
    }

    &.visma-pictogram-phone-charged {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_phone_charged.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_phone_charged.svg');
        }
    }

    &.visma-pictogram-phone-declined {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_phone_declined.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_phone_declined.svg');
        }
    }

    &.visma-pictogram-piggy-bank {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_piggy_bank.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_piggy_bank.svg');
        }
    }

    &.visma-pictogram-platinum-rank {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_platinum_rank.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_platinum_rank.svg');
        }
    }

    &.visma-pictogram-platinum-rank-2 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_platinum_rank_2.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_platinum_rank_2.svg');
        }
    }

    &.visma-pictogram-platinum-rank-3 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_platinum_rank_3.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_platinum_rank_3.svg');
        }
    }

    &.visma-pictogram-profile {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_profile.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_profile.svg');
        }
    }

    &.visma-pictogram-profile-search {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_profile_search.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_profile_search.svg');
        }
    }

    &.visma-pictogram-purchase-order {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_purchase_order.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_purchase_order.svg');
        }
    }

    &.visma-pictogram-purchase-order-2 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_purchase_order_2.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_purchase_order_2.svg');
        }
    }

    &.visma-pictogram-question-mark {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_question_mark.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_question_mark.svg');
        }
    }

    &.visma-pictogram-receive {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_receive.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_receive.svg');
        }
    }

    &.visma-pictogram-recycle-bin {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_recycle_bin.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_recycle_bin.svg');
        }
    }

    &.visma-pictogram-recycled-can {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_recycled_can.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_recycled_can.svg');
        }
    }

    &.visma-pictogram-remove-user {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_remove_user.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_remove_user.svg');
        }
    }

    &.visma-pictogram-report {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_report.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_report.svg');
        }
    }

    &.visma-pictogram-report-police {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_report_police.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_report_police.svg');
        }
    }

    &.visma-pictogram-report-scan {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_report_scan.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_report_scan.svg');
        }
    }

    &.visma-pictogram-report-search {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_report_search.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_report_search.svg');
        }
    }

    &.visma-pictogram-report-sent {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_report_sent.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_report_sent.svg');
        }
    }

    &.visma-pictogram-report-undo {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_report_undo.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_report_undo.svg');
        }
    }

    &.visma-pictogram-report-user {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_report_user.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_report_user.svg');
        }
    }

    &.visma-pictogram-retail {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_retail.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_retail.svg');
        }
    }

    &.visma-pictogram-retail-2 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_retail_2.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_retail_2.svg');
        }
    }

    &.visma-pictogram-retail-payment {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_retail_payment.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_retail_payment.svg');
        }
    }

    &.visma-pictogram-robot {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_robot.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_robot.svg');
        }
    }

    &.visma-pictogram-rocket {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_rocket.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_rocket.svg');
        }
    }

    &.visma-pictogram-search {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_search.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_search.svg');
        }
    }

    &.visma-pictogram-search-2 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_search_2.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_search_2.svg');
        }
    }

    &.visma-pictogram-search-code {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_search_code.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_search_code.svg');
        }
    }

    &.visma-pictogram-search-invoice {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_search_invoice.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_search_invoice.svg');
        }
    }

    &.visma-pictogram-security-code {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_security_code.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_security_code.svg');
        }
    }

    &.visma-pictogram-service {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_service.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_service.svg');
        }
    }

    &.visma-pictogram-settings {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_settings.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_settings.svg');
        }
    }

    &.visma-pictogram-shop {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_shop.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_shop.svg');
        }
    }

    &.visma-pictogram-shopping-basket {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_shopping_basket.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_shopping_basket.svg');
        }
    }

    &.visma-pictogram-shopping-cart {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_shopping_cart.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_shopping_cart.svg');
        }
    }

    &.visma-pictogram-silver-rank {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_silver_rank.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_silver_rank.svg');
        }
    }

    &.visma-pictogram-silver-rank-2 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_silver_rank_2.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_silver_rank_2.svg');
        }
    }

    &.visma-pictogram-silver-rank-3 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_silver_rank_3.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_silver_rank_3.svg');
        }
    }

    &.visma-pictogram-ski {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_ski.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_ski.svg');
        }
    }

    &.visma-pictogram-ski-2 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_ski_2.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_ski_2.svg');
        }
    }

    &.visma-pictogram-smiley {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_smiley.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_smiley.svg');
        }
    }

    &.visma-pictogram-smiley-2 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_smiley_2.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_smiley_2.svg');
        }
    }

    &.visma-pictogram-space-planet {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_space_planet.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_space_planet.svg');
        }
    }

    &.visma-pictogram-star {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_star.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_star.svg');
        }
    }

    &.visma-pictogram-stored-files {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_stored_files.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_stored_files.svg');
        }
    }

    &.visma-pictogram-stroke-default {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_stroke_default.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_stroke_default.svg');
        }
    }

    &.visma-pictogram-support {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_support.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_support.svg');
        }
    }

    &.visma-pictogram-switch-settings {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_switch_settings.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_switch_settings.svg');
        }
    }

    &.visma-pictogram-target {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_target.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_target.svg');
        }
    }

    &.visma-pictogram-tax-payment {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_tax_payment.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_tax_payment.svg');
        }
    }

    &.visma-pictogram-thermometer {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_thermometer.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_thermometer.svg');
        }
    }

    &.visma-pictogram-time-checked {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_time_checked.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_time_checked.svg');
        }
    }

    &.visma-pictogram-time-report {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_time_report.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_time_report.svg');
        }
    }

    &.visma-pictogram-time-report-2 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_time_report_2.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_time_report_2.svg');
        }
    }

    &.visma-pictogram-track-location {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_track_location.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_track_location.svg');
        }
    }

    &.visma-pictogram-trolley {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_trolley.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_trolley.svg');
        }
    }

    &.visma-pictogram-trolley-2 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_trolley_2.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_trolley_2.svg');
        }
    }

    &.visma-pictogram-trolley-clicked {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_trolley_clicked.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_trolley_clicked.svg');
        }
    }

    &.visma-pictogram-trolley-hand {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_trolley_hand.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_trolley_hand.svg');
        }
    }

    &.visma-pictogram-trolley-settings {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_trolley_settings.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_trolley_settings.svg');
        }
    }

    &.visma-pictogram-truck {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_truck.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_truck.svg');
        }
    }

    &.visma-pictogram-tv {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_tv.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_tv.svg');
        }
    }

    &.visma-pictogram-user-arrow-up {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_user_arrow_up.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_user_arrow_up.svg');
        }
    }

    &.visma-pictogram-user_calendar {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_user_calendar.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_user_calendar.svg');
        }
    }

    &.visma-pictogram-user_calendar-2 {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_user_calendar_2.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_user_calendar_2.svg');
        }
    }

    &.visma-pictogram-user-candidate {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_user_candidate.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_user_candidate.svg');
        }
    }

    &.visma-pictogram-user-community {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_user_community.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_user_community.svg');
        }
    }

    &.visma-pictogram-user-graduation {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_user_graduation.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_user_graduation.svg');
        }
    }

    &.visma-pictogram-user-heart {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_user_heart.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_user_heart.svg');
        }
    }

    &.visma-pictogram-user-hierarchy {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_user_hierarchy.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_user_hierarchy.svg');
        }
    }

    &.visma-pictogram-user-idea {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_user_idea.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_user_idea.svg');
        }
    }

    &.visma-pictogram-user-laptop {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_user_laptop.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_user_laptop.svg');
        }
    }

    &.visma-pictogram-user-money {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_user_money.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_user_money.svg');
        }
    }

    &.visma-pictogram-user-search {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_user_search.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_user_search.svg');
        }
    }

    &.visma-pictogram-vacation-beach {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_vacation_beach.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_vacation_beach.svg');
        }
    }

    &.visma-pictogram-video {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_video.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_video.svg');
        }
    }

    &.visma-pictogram-video-player {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_video_player.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_video_player.svg');
        }
    }

    &.visma-pictogram-warehouse {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_warehouse.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_warehouse.svg');
        }
    }

    &.visma-pictogram-water-bottle {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_water_bottle.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_water_bottle.svg');
        }
    }

    &.visma-pictogram-whistblower {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_whistblower.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_whistblower.svg');
        }
    }

    &.visma-pictogram-work-from-home {
        &::before {
            -webkit-mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_work_from_home.svg');
            mask-image: url('#{$vud-pictograms-path}/pictograms-monochrome/pictogram_work_from_home.svg');
        }
    }
}


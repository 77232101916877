
//# Interface controls icons

//*** scss local variable ***

$vismaicon-datepicker-mask-img:                url('#{$vud-icons-path}/interface-controls/datepicker.svg');
$vismaicon-timepicker-mask-img:                url('#{$vud-icons-path}/interface-controls/timepicker.svg');
$vismaicon-autocomplete-mask-img:              url('#{$vud-icons-path}/interface-controls/autocomplete.svg');
$vismaicon-multiselection-mask-img:            url('#{$vud-icons-path}/interface-controls/multiselection.svg');
$vismaicon-close-mask-img:                     url('#{$vud-icons-path}/interface-controls-dynamic/close.svg');


$vismaicon-dynamic-calendar-mask-img:          url('#{$vud-icons-path}/worksurface-dynamic/24_datepicker.svg');
$vismaicon-dynamic-sm-calendar-mask-img:       url('#{$vud-icons-path}/worksurface-dynamic/16_datepicker.svg');

$vismaicon-dynamic-autocomplete-mask-img:      url('#{$vud-icons-path}/worksurface-dynamic/24_autocomplete.svg');
$vismaicon-dynamic-sm-autocomplete-mask-img:   url('#{$vud-icons-path}/worksurface-dynamic/16_autocomplete.svg');

$vismaicon-dynamic-multiselection-mask-img:    url('#{$vud-icons-path}/worksurface-dynamic/24_multiselection.svg');
$vismaicon-dynamic-sm-multiselection-mask-img: url('#{$vud-icons-path}/worksurface-dynamic/16_multiselection.svg');

$vismaicon-dynamic-close-mask-img:             url('#{$vud-icons-path}/worksurface-dynamic/24_close.svg');
$vismaicon-dynamic-sm-close-mask-img:          url('#{$vud-icons-path}/worksurface-dynamic/16_close.svg');


//*** element/component code ***
.vismaicon {
    // standard/clasic set
    &.vismaicon-datepicker {
        &:before { 
            -webkit-mask-image: $vismaicon-datepicker-mask-img;
            mask-image: $vismaicon-datepicker-mask-img;
        }
    }

    &.vismaicon-timepicker {
        &:before { 
            -webkit-mask-image: $vismaicon-timepicker-mask-img;
            mask-image: $vismaicon-timepicker-mask-img;
        }
    }

    &.vismaicon-autocomplete {
        &:before {
            -webkit-mask-image: $vismaicon-autocomplete-mask-img;
            mask-image: $vismaicon-autocomplete-mask-img;
        }
    }

    .vismaicon-multiselection {
        &:before {
            -webkit-mask-image: $vismaicon-multiselection-mask-img;
            mask-image: $vismaicon-multiselection-mask-img;
        }
    }

    .vismaicon-close {
        &:before {
            -webkit-mask-image: $vismaicon-close-mask-img;
            mask-image: $vismaicon-close-mask-img;
        }
    }


    // dynamic set
    &.vismaicon-dynamic {
        &.vismaicon-calendar,
        &.vismaicon-datepicker {
            &:before {
                -webkit-mask-image: $vismaicon-dynamic-calendar-mask-img;
                mask-image: $vismaicon-dynamic-calendar-mask-img;
            }
            &.vismaicon-sm:before {
                -webkit-mask-image: $vismaicon-dynamic-sm-calendar-mask-img;
                mask-image: $vismaicon-dynamic-sm-calendar-mask-img;
            }
        }

        &.vismaicon-autocomplete {
            &:before {
                -webkit-mask-image: $vismaicon-dynamic-autocomplete-mask-img;
                mask-image: $vismaicon-dynamic-autocomplete-mask-img;
            }
            &.vismaicon-sm:before { -webkit-mask-image: $vismaicon-dynamic-sm-autocomplete-mask-img;
                mask-image: $vismaicon-dynamic-sm-autocomplete-mask-img;
            }
        }

        &.vismaicon-multiselection {
            &:before {
                -webkit-mask-image: $vismaicon-dynamic-multiselection-mask-img;
                mask-image: $vismaicon-dynamic-multiselection-mask-img;
            }
            &.vismaicon-sm:before {
                -webkit-mask-image: $vismaicon-dynamic-sm-multiselection-mask-img;
                mask-image: $vismaicon-dynamic-sm-multiselection-mask-img;
            }
        }

        &.vismaicon-close {
            &:before {
                -webkit-mask-image: $vismaicon-dynamic-close-mask-img;
                mask-image: $vismaicon-dynamic-close-mask-img;
            }
            &.vismaicon-sm:before {
                -webkit-mask-image: $vismaicon-dynamic-sm-close-mask-img;
                mask-image: $vismaicon-dynamic-sm-close-mask-img;
            }
        }
    }
}

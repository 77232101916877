
//# Informative icons

//*** scss local variable ***
$vismaicon-sm-width:              rem(16px);
$vismaicon-sm-height:             rem(16px);
$vismaicon-md-width:              rem(24px);
$vismaicon-md-height:             rem(24px);
$vismaicon-lg-width:              rem(48px);
$vismaicon-lg-height:             rem(48px);

$vismaicon-error-bg:              var(--icon-informative-error-bg);
$vismaicon-warning-bg:            var(--icon-informative-warning-bg);
$vismaicon-success-bg:            var(--icon-informative-success-bg);
$vismaicon-info-bg:               var(--icon-informative-info-bg);
$vismaicon-help-bg:               var(--icon-informative-help-bg);


//*** Informative Standard icons ***
// info
$vismaicon-standard-sm-info-mask-img:  url('#{$vud-icons-path}/informative-filled-icons/informative_icon_info_sm.svg');
$vismaicon-standard-md-info-mask-img:  url('#{$vud-icons-path}/informative-filled-icons/informative_icon_info_md.svg');
$vismaicon-standard-lg-info-mask-img:  url('#{$vud-icons-path}/informative-filled-icons/informative_icon_info_lg.svg');

// help
$vismaicon-standard-sm-help-mask-img:  url('#{$vud-icons-path}/informative-filled-icons/informative_icon_help_sm.svg');
$vismaicon-standard-md-help-mask-img:  url('#{$vud-icons-path}/informative-filled-icons/informative_icon_help_md.svg');
$vismaicon-standard-lg-help-mask-img:  url('#{$vud-icons-path}/informative-filled-icons/informative_icon_help_lg.svg');

// success
$vismaicon-standard-sm-success-mask-img:  url('#{$vud-icons-path}/informative-filled-icons/informative_icon_success_sm.svg');
$vismaicon-standard-md-success-mask-img:  url('#{$vud-icons-path}/informative-filled-icons/informative_icon_success_md.svg');
$vismaicon-standard-lg-success-mask-img:  url('#{$vud-icons-path}/informative-filled-icons/informative_icon_success_lg.svg');

// warning
$vismaicon-standard-sm-warning-mask-img:  url('#{$vud-icons-path}/informative-filled-icons/informative_icon_warning_sm.svg');
$vismaicon-standard-md-warning-mask-img:  url('#{$vud-icons-path}/informative-filled-icons/informative_icon_warning_md.svg');
$vismaicon-standard-lg-warning-mask-img:  url('#{$vud-icons-path}/informative-filled-icons/informative_icon_warning_lg.svg');

// error
$vismaicon-standard-sm-error-mask-img:  url('#{$vud-icons-path}/informative-filled-icons/informative_icon_error_sm.svg');
$vismaicon-standard-md-error-mask-img:  url('#{$vud-icons-path}/informative-filled-icons/informative_icon_error_md.svg');
$vismaicon-standard-lg-error-mask-img:  url('#{$vud-icons-path}/informative-filled-icons/informative_icon_error_lg.svg');


//*** Informative Dynamic icons ***
// info
$vismaicon-dynamic-sm-info-mask-img:  url('#{$vud-icons-path}/informative-filled-icons-dynamic/informative_icon_info_sm.svg');
$vismaicon-dynamic-md-info-mask-img:  url('#{$vud-icons-path}/informative-filled-icons-dynamic/informative_icon_info_md.svg');
$vismaicon-dynamic-lg-info-mask-img:  url('#{$vud-icons-path}/informative-filled-icons-dynamic/informative_icon_info_lg.svg');

// help
$vismaicon-dynamic-sm-help-mask-img:  url('#{$vud-icons-path}/informative-filled-icons-dynamic/informative_icon_help_sm.svg');
$vismaicon-dynamic-md-help-mask-img:  url('#{$vud-icons-path}/informative-filled-icons-dynamic/informative_icon_help_md.svg');
$vismaicon-dynamic-lg-help-mask-img:  url('#{$vud-icons-path}/informative-filled-icons-dynamic/informative_icon_help_lg.svg');

// success
$vismaicon-dynamic-sm-success-mask-img:  url('#{$vud-icons-path}/informative-filled-icons-dynamic/informative_icon_success_sm.svg');
$vismaicon-dynamic-md-success-mask-img:  url('#{$vud-icons-path}/informative-filled-icons-dynamic/informative_icon_success_md.svg');
$vismaicon-dynamic-lg-success-mask-img:  url('#{$vud-icons-path}/informative-filled-icons-dynamic/informative_icon_success_lg.svg');

// warning
$vismaicon-dynamic-sm-warning-mask-img:  url('#{$vud-icons-path}/informative-filled-icons-dynamic/informative_icon_warning_sm.svg');
$vismaicon-dynamic-md-warning-mask-img:  url('#{$vud-icons-path}/informative-filled-icons-dynamic/informative_icon_warning_md.svg');
$vismaicon-dynamic-lg-warning-mask-img:  url('#{$vud-icons-path}/informative-filled-icons-dynamic/informative_icon_warning_lg.svg');

// error
$vismaicon-dynamic-sm-error-mask-img:  url('#{$vud-icons-path}/informative-filled-icons-dynamic/informative_icon_error_sm.svg');
$vismaicon-dynamic-md-error-mask-img:  url('#{$vud-icons-path}/informative-filled-icons-dynamic/informative_icon_error_md.svg');
$vismaicon-dynamic-lg-error-mask-img:  url('#{$vud-icons-path}/informative-filled-icons-dynamic/informative_icon_error_lg.svg');



//*** element/component code ***
.vismaicon {
    &.vismaicon-filled {
        &, &.vismaicon-dynamic {
            &:before {
                width: $vismaicon-md-width;
                height: $vismaicon-md-height;
                mask-position: 0 0;
                -webkit-mask-position: 0 0;
                mask-size: auto;
                -webkit-mask-size: auto;
            }
        
            &.vismaicon-sm:before {
                width: $vismaicon-sm-width;
                height: $vismaicon-sm-height;
                mask-position: 0 0;
                -webkit-mask-position: 0 0;
                mask-size: auto;
                -webkit-mask-size: auto;
            }
        
            &.vismaicon-lg:before {
                width: $vismaicon-lg-width;
                height: $vismaicon-lg-height;
                mask-position: 0 0;
                -webkit-mask-position: 0 0;
                mask-size: auto;
                -webkit-mask-size: auto;
            }
        }

        &.vismaicon-error {
            &:before {
                background-color: $vismaicon-error-bg;
                mask-image: $vismaicon-standard-md-error-mask-img;
                -webkit-mask-image: $vismaicon-standard-md-error-mask-img;
            }
        
            &.vismaicon-lg:before {
                mask-image: $vismaicon-standard-lg-error-mask-img;
                -webkit-mask-image: $vismaicon-standard-lg-error-mask-img;
            }
        
            &.vismaicon-sm:before {
                mask-image: $vismaicon-standard-sm-error-mask-img;
                -webkit-mask-image: $vismaicon-standard-sm-error-mask-img;
            }
        }
        
        &.vismaicon-warning {
            &:before {
                background-color: $vismaicon-warning-bg;
                mask-image: $vismaicon-standard-md-warning-mask-img;
                -webkit-mask-image: $vismaicon-standard-md-warning-mask-img;
            }
        
            &.vismaicon-lg:before {
                mask-image: $vismaicon-standard-lg-warning-mask-img;
                -webkit-mask-image: $vismaicon-standard-lg-warning-mask-img;
            }
        
            &.vismaicon-sm:before {
                mask-image: $vismaicon-standard-sm-warning-mask-img;
                -webkit-mask-image: $vismaicon-standard-sm-warning-mask-img;
            }
        }
        
        &.vismaicon-info:not(.vismaicon-menu) {
            &:before {
                background-color: $vismaicon-info-bg;
                mask-image: $vismaicon-standard-md-info-mask-img;
                -webkit-mask-image: $vismaicon-standard-md-info-mask-img;
            }
        
            &.vismaicon-lg:before {
                mask-image: $vismaicon-standard-lg-info-mask-img;
                -webkit-mask-image: $vismaicon-standard-lg-info-mask-img;
            }
        
            &.vismaicon-sm:before {
                mask-image: $vismaicon-standard-sm-info-mask-img;
                -webkit-mask-image: $vismaicon-standard-sm-info-mask-img;
            }
        }
        
        &.vismaicon-success {
            &:before {
                background-color: $vismaicon-success-bg;
                mask-image: $vismaicon-standard-md-success-mask-img;
                -webkit-mask-image: $vismaicon-standard-md-success-mask-img;
            }
        
            &.vismaicon-lg:before {
                mask-image: $vismaicon-standard-lg-success-mask-img;
                -webkit-mask-image: $vismaicon-standard-lg-success-mask-img;
            }
        
            &.vismaicon-sm:before {
                mask-image: $vismaicon-standard-sm-success-mask-img;
                -webkit-mask-image: $vismaicon-standard-sm-success-mask-img;
            }
        }
        
        &.vismaicon-help {
            &:before {
                background-color: $vismaicon-help-bg;
                mask-image: $vismaicon-standard-md-help-mask-img;
                -webkit-mask-image: $vismaicon-standard-md-help-mask-img;
            }
        
            &.vismaicon-lg:before {
                mask-image: $vismaicon-standard-lg-help-mask-img;
                -webkit-mask-image: $vismaicon-standard-lg-help-mask-img;
            }
        
            &.vismaicon-sm:before {
                mask-image: $vismaicon-standard-sm-help-mask-img;
                -webkit-mask-image: $vismaicon-standard-sm-help-mask-img;
            }
        }
    
        &.vismaicon-dynamic {
            &.vismaicon-error {
                &:before {
                    background-color: $vismaicon-error-bg;
                    mask-image: $vismaicon-dynamic-md-error-mask-img;
                    -webkit-mask-image: $vismaicon-dynamic-md-error-mask-img;
                }
            
                &.vismaicon-lg:before {
                    mask-image: $vismaicon-dynamic-lg-error-mask-img;
                    -webkit-mask-image: $vismaicon-dynamic-lg-error-mask-img;
                }
            
                &.vismaicon-sm:before {
                    mask-image: $vismaicon-dynamic-sm-error-mask-img;
                    -webkit-mask-image: $vismaicon-dynamic-sm-error-mask-img;
                }
            }
            
            &.vismaicon-warning {
                &:before {
                    background-color: $vismaicon-warning-bg;
                    mask-image: $vismaicon-dynamic-md-warning-mask-img;
                    -webkit-mask-image: $vismaicon-dynamic-md-warning-mask-img;
                }
            
                &.vismaicon-lg:before {
                    mask-image: $vismaicon-dynamic-lg-warning-mask-img;
                    -webkit-mask-image: $vismaicon-dynamic-lg-warning-mask-img;
                }
            
                &.vismaicon-sm:before {
                    mask-image: $vismaicon-dynamic-sm-warning-mask-img;
                    -webkit-mask-image: $vismaicon-dynamic-sm-warning-mask-img;
                }
            }
            
            &.vismaicon-info:not(.vismaicon-menu) {
                &:before {
                    background-color: $vismaicon-info-bg;
                    mask-image: $vismaicon-dynamic-md-info-mask-img;
                    -webkit-mask-image: $vismaicon-dynamic-md-info-mask-img;
                }
            
                &.vismaicon-lg:before {
                    mask-image: $vismaicon-dynamic-lg-info-mask-img;
                    -webkit-mask-image: $vismaicon-dynamic-lg-info-mask-img;
                }
            
                &.vismaicon-sm:before {
                    mask-image: $vismaicon-dynamic-sm-info-mask-img;
                    -webkit-mask-image: $vismaicon-dynamic-sm-info-mask-img;
                }
            }
            
            &.vismaicon-success {
                &:before {
                    background-color: $vismaicon-success-bg;
                    mask-image: $vismaicon-dynamic-md-success-mask-img;
                    -webkit-mask-image: $vismaicon-dynamic-md-success-mask-img;
                }
            
                &.vismaicon-lg:before {
                    mask-image: $vismaicon-dynamic-lg-success-mask-img;
                    -webkit-mask-image: $vismaicon-dynamic-lg-success-mask-img;
                }
            
                &.vismaicon-sm:before {
                    mask-image: $vismaicon-dynamic-sm-success-mask-img;
                    -webkit-mask-image: $vismaicon-dynamic-sm-success-mask-img;
                }
            }
            
            &.vismaicon-help {
                &:before {
                    background-color: $vismaicon-help-bg;
                    mask-image: $vismaicon-dynamic-md-help-mask-img;
                    -webkit-mask-image: $vismaicon-dynamic-md-help-mask-img;
                }
            
                &.vismaicon-lg:before {
                    mask-image: $vismaicon-dynamic-lg-help-mask-img;
                    -webkit-mask-image: $vismaicon-dynamic-lg-help-mask-img;
                }
            
                &.vismaicon-sm:before {
                    mask-image: $vismaicon-dynamic-sm-help-mask-img;
                    -webkit-mask-image: $vismaicon-dynamic-sm-help-mask-img;
                }
            }
        }
    }
}


//# Icons

//*** scss local variable ***
$vismaicon-width:             rem(24px);
$vismaicon-height:            rem(24px);

$vismaicon-sm-width:          rem(16px);
$vismaicon-sm-height:         rem(16px);

$vismaicon-bg:                var(--icon-bg);
$vismaicon-hover-bg:          var(--icon-hover-bg);
$vismaicon-disabled-bg:       var(--icon-disabled-bg);

$vismaicon-button-primary-bg: var(--button-primary-icon);


//*** element/component code ***
//=== Basic icon implementation
.vismaicon {
    display: inline-block;
    position: relative;
    user-select: none;

    &::before { 
        display: block;
        content: "" !important;
        position: relative;
        width: $vismaicon-width;
        height: $vismaicon-height;
        margin: 0 auto;

        background: {
            color: transparent; 
            size: cover; 
            repeat: no-repeat;
        };
        -webkit-mask-position: 0 0;
        mask-position: 0 0;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-size: cover;
        mask-size: cover;
    }

    &:not(.vismaicon-filled) {
        &::before { background-color: $vismaicon-bg; }
    }

    // hover state, only for icon's in button or links wrappers
    .hover:not(:active) > &, &.hover:not(:active),
    .btn-link:hover:not(:active) > & {
        &:not(.vismaicon-filled) {
            &::before { background-color: $vismaicon-hover-bg; }
        }
    }

    // active state, only for icon's in button or links wrappers
    .active &, &.active,
    .btn-link:active &,
    .btn-icon:active & {
        &:not(.vismaicon-filled) {
            &::before { background-color: $vismaicon-bg; }
        }
    }

    // selected state
    .selected > & {
        cursor: pointer;
        
        &::before { 
            mask-position: rem(-29px) 0; 
            -webkit-mask-position: rem(-29px) 0; 
        }
    }

    // disabled state
    .disabled &, &.disabled,
    :disabled &, &:disabled,
    .btn:disabled &,
    .btn-link:disabled &,
    .btn-icon:disabled & {
        pointer-events: none;

        // &:not(.vismaicon-filled) {
            &::before { background-color: $vismaicon-disabled-bg !important; }
        // }
    } 

    // '16px' icon version
    &.vismaicon-sm {
        //top: rem(2px);

        &::before {
            width: $vismaicon-sm-width;
            height: $vismaicon-sm-height;
        }

        // selected state
        .selected & {
            &::before { 
                mask-position: rem(-21px) 0; 
                -webkit-mask-position: rem(-21px) 0;
            }
        }
    }

    .btn > &,
    a > & {
        top: 0;
    }

    .btn > &,
    a > & {
        margin-right: rem(8px);
    }

    .btn-primary > &,
    .btn-primary:hover:not(:active) > &,
    .btn-primary:active > & {
        &:not(.vismaicon-media) {
            &::before {
                background-color: $vismaicon-button-primary-bg;
            }
        }
    }

    .btn.disabled > &,
    .btn.disabled:hover:not(:active) > &,
    .btn.disabled:active > &,
    .btn:disabled > &,
    .btn:disabled:hover:not(:active) > &,
    .btn:disabled:active > & {
        &:not(.vismaicon-media) {
            &::before {
                background-color: $vismaicon-disabled-bg;
            }
        }
    }

    // disabled state for icons in primary color buttons
    .disabled.btn-primary > &, .btn-primary > &.disabled,
    .btn-primary:disabled > &, .btn-primary > &:disabled,
    .btn.btn-primary > :disabled & {
        opacity: .6;
    }
}

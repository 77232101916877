
/*! VUD Icons (Visma Unified Design) - v1.8.2 */

//# path overwite and units setup;
$prefix-path: '..' !default;

// VUD icons path
$vud-icons-path: '#{$prefix-path}/img/vismaicons' !default;
$vud-pictograms-path: '#{$prefix-path}/img/pictograms' !default;

//== Config
@import 'icons/icon-config';

/* Setup a few global scope properties */
//=== Theme Variables

/* A. Ligth Mode */
:root, :host,
html[data-theme='light'], html.light-theme {
  @include light-mode();
  }


/* B. Dark Mode */
html[data-theme='dark']:not(.light-theme), html.dark-theme:not([data-theme='light']):not(.light-theme) {
  @include dark-mode();
  }


// // VUD icons expansion set
// SCSS for icons
@import 'icons/icons-base';

//== Import all icons
// worksurface
@import 'icons/icons-worksurface-standard';
@import 'icons/icons-worksurface-dynamic';

// action
@import 'icons/icons-action-standard';
@import 'icons/icons-action-dynamic';

// informative
@import 'icons/icons-informative';
@import 'icons/icons-interface-controls';

// social media
@import 'icons/icons-social-media';

// flags
@import 'icons/icons-flags';

// pictograms
@import 'pictograms/pictograms';

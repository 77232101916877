/**** Flags ****/
.vismaicon {
    &.vismaicon-flags {
        &::before {
            background-color: transparent;
            -webkit-mask-image: none;
            mask-image: none;
        }
    }

    &.vismaicon-argentina::before     { background-image: url('#{$vud-icons-path}/flags/24_Argentina.svg'); }
    &.vismaicon-australia::before     { background-image: url('#{$vud-icons-path}/flags/24_Australia.svg'); }
    &.vismaicon-austria::before       { background-image: url('#{$vud-icons-path}/flags/24_Austria.svg'); }
    &.vismaicon-azerbaijan::before    { background-image: url('#{$vud-icons-path}/flags/24_Azerbaijan.svg'); }
    &.vismaicon-belgium::before       { background-image: url('#{$vud-icons-path}/flags/24_Belgium.svg'); }
    &.vismaicon-brazil::before        { background-image: url('#{$vud-icons-path}/flags/24_Brazil.svg'); }
    &.vismaicon-chile::before         { background-image: url('#{$vud-icons-path}/flags/24_Chile.svg'); }
    &.vismaicon-china::before         { background-image: url('#{$vud-icons-path}/flags/24_China.svg'); }
    &.vismaicon-columbia::before      { background-image: url('#{$vud-icons-path}/flags/24_Columbia.svg'); }
    &.vismaicon-czechia::before       { background-image: url('#{$vud-icons-path}/flags/24_Czechia.svg'); }
    &.vismaicon-denmark::before       { background-image: url('#{$vud-icons-path}/flags/24_Denmark.svg'); }
    &.vismaicon-ecuador::before       { background-image: url('#{$vud-icons-path}/flags/24_Ecuador.svg'); }
    &.vismaicon-estonia::before       { background-image: url('#{$vud-icons-path}/flags/24_Estonia.svg'); }
    &.vismaicon-finland::before       { background-image: url('#{$vud-icons-path}/flags/24_Finland.svg'); }
    &.vismaicon-france::before        { background-image: url('#{$vud-icons-path}/flags/24_France.svg'); }
    &.vismaicon-germany::before       { background-image: url('#{$vud-icons-path}/flags/24_Germany.svg'); }
    &.vismaicon-greece::before        { background-image: url('#{$vud-icons-path}/flags/24_Greece.svg'); }
    &.vismaicon-greenland::before     { background-image: url('#{$vud-icons-path}/flags/24_Greenland.svg'); }
    &.vismaicon-hong-kong::before     { background-image: url('#{$vud-icons-path}/flags/24_Hong_Kong.svg'); }
    &.vismaicon-hungary::before       { background-image: url('#{$vud-icons-path}/flags/24_Hungary.svg'); }
    &.vismaicon-iceland::before       { background-image: url('#{$vud-icons-path}/flags/24_Iceland.svg'); }
    &.vismaicon-italy::before         { background-image: url('#{$vud-icons-path}/flags/24_Italy.svg'); }
    &.vismaicon-kazakhstan::before    { background-image: url('#{$vud-icons-path}/flags/24_Kazakhstan.svg'); }
    &.vismaicon-latvia::before        { background-image: url('#{$vud-icons-path}/flags/24_Latvia.svg'); }
    &.vismaicon-lithuania::before     { background-image: url('#{$vud-icons-path}/flags/24_Lithuania.svg'); }
    &.vismaicon-macao::before         { background-image: url('#{$vud-icons-path}/flags/24_Macao.svg'); }
    &.vismaicon-mexic::before         { background-image: url('#{$vud-icons-path}/flags/24_Mexic.svg'); }
    &.vismaicon-nederlands::before    { background-image: url('#{$vud-icons-path}/flags/24_Nederlands.svg'); }
    &.vismaicon-norway::before        { background-image: url('#{$vud-icons-path}/flags/24_Norway.svg'); }
    &.vismaicon-peru::before          { background-image: url('#{$vud-icons-path}/flags/24_Peru.svg'); }
    &.vismaicon-poland::before        { background-image: url('#{$vud-icons-path}/flags/24_Poland.svg'); }
    &.vismaicon-portugal::before      { background-image: url('#{$vud-icons-path}/flags/24_Portugal.svg'); }
    &.vismaicon-romania::before       { background-image: url('#{$vud-icons-path}/flags/24_Romania.svg'); }
    &.vismaicon-russia::before        { background-image: url('#{$vud-icons-path}/flags/24_Russia.svg'); }
    &.vismaicon-sami::before          { background-image: url('#{$vud-icons-path}/flags/24_Sami.svg'); }
    &.vismaicon-saudit-arabia::before { background-image: url('#{$vud-icons-path}/flags/24_Saudit_Arabia.svg'); }
    &.vismaicon-spain::before         { background-image: url('#{$vud-icons-path}/flags/24_Spain.svg'); }
    &.vismaicon-sweden::before        { background-image: url('#{$vud-icons-path}/flags/24_Sweden.svg'); }
    &.vismaicon-switzerland::before   { background-image: url('#{$vud-icons-path}/flags/24_Switzerland.svg'); }
    &.vismaicon-taiwan::before        { background-image: url('#{$vud-icons-path}/flags/24_Taiwan.svg'); }
    &.vismaicon-tajikistan::before    { background-image: url('#{$vud-icons-path}/flags/24_Tajikistan.svg'); }
    &.vismaicon-uk::before            { background-image: url('#{$vud-icons-path}/flags/24_UK.svg'); }
    &.vismaicon-usa::before           { background-image: url('#{$vud-icons-path}/flags/24_USA.svg'); }
    &.vismaicon-uzbekistan::before    { background-image: url('#{$vud-icons-path}/flags/24_Uzbekistan.svg'); }
}

/**** Standard icons - worksurface icons ****/

    .vismaicon-absence {
        &:before,
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_absence.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_absence.svg');
        }
    }

    .vismaicon-absence-1 {
        &:before,
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_absence_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_absence_1.svg');
        }
    }

    .vismaicon-absence-2 {
        &:before,
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_absence_2.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_absence_2.svg'); 
        }
    }

    .vismaicon-add {
        &:before,
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_add.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_add.svg');
        }
    }

    .vismaicon-add-attachement {
        &:before,
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_add_attachement.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_add_attachement.svg');
        }
    }

    .vismaicon-add-comment {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_add_comment.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_add_comment.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/16_add_comment.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_add_comment.svg');
        }
    }

    .vismaicon-add-to-checklist {
        &:before,
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_add_to_checklist.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_add_to_checklist.svg');
        }
    }

    .vismaicon-add-information {
        &:before,
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_add_information.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_add_information.svg');
        }
    }

    .vismaicon-add-schedule {
        &:before,
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_add_schedule.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_add_schedule.svg');
        }
    }
    
    .vismaicon-add-to-favorite {
        &:before,
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_add_to_favorite.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_add_to_favorite.svg');
        }
    }

    .vismaicon-adjust-hours {
        &:before,
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_adjust_hours.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_adjust_hours.svg');
        }
    }

    .vismaicon-adoption-leave {
        &:before,
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_adoption_leave.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_adoption_leave.svg');
        }
    }

    .vismaicon-airplane {
        &:before,
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_airplane.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_airplane.svg');
        }
    }

    .vismaicon-approved {
        &:before,
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_approved.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_approved.svg');
        }

    }

    .vismaicon-approval {
        &:before, 
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_approval.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_approval.svg');
        }
    }

    .vismaicon-approve-order {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_approve_order.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_approve_order.svg');
        }
    }

    .vismaicon-attach {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_attach.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_attach.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/16_attach.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_attach.svg');
        }
    }

    .vismaicon-attendance {
        &:before,
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_attendance.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_attendance.svg');
        }
    }

    .vismaicon-autoadjust-columns {
        &:before,
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_autoadjust_columns.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_autoadjust_columns.svg');
        }
    }

    .vismaicon-banking {
        &:before,
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_banking.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_banking.svg');
        }
    }
    
    .vismaicon-barcode {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_barcode.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_barcode.svg');
        }
    }

    .vismaicon-battery {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_battery.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_battery.svg');
        }

    }

    .vismaicon-battery-1 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_battery_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_battery_1.svg');
        }
    }

    .vismaicon-battery-2 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_battery_2.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_battery_2.svg');
        }
    }

    .vismaicon-battery-3 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_battery_3.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_battery_3.svg');
        }
    }

    .vismaicon-bicycle {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_bicycle.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_bicycle.svg');
        }
    }

    .vismaicon-birth {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_birth.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_birth.svg');
        }
    } 
    
    .vismaicon-briefcase {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_briefcase.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_briefcase.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/16_briefcase.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_briefcase.svg');
        }
    }

    .vismaicon-briefcase-1 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_briefcase_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_briefcase_1.svg');
        }
    }

    .vismaicon-bluetooth {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_bluetooth.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_bluetooth.svg');
        }
    }

    .vismaicon-book-equipment {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_book_equipment.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_book_equipment.svg');
        }
    }

    .vismaicon-bonus-gift {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_bonus_gift.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_bonus_gift.svg');
        }
    }

    .vismaicon-box {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_box.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_box.svg');
        }

    }

    .vismaicon-breadcrumb-folder {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_breadcrumb_folder.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_breadcrumb_folder.svg');
        }
    }

    .vismaicon-burger {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_burger.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_burger.svg');
        }
    }

    .vismaicon-bus {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_bus.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_bus.svg');
        }
    }

    .vismaicon-calculator {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_calculator.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_calculator.svg');
        }
    }

    .vismaicon-car {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_car.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_car.svg');
        }
    }

    .vismaicon-car-1 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_car_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_car_1.svg');
        }
    }

    .vismaicon-car-2 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_car_2.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_car_2.svg');
        }
    }

    .vismaicon-change-width {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_change_width.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_change_width.svg');
        }
    }

    .vismaicon-chat,
    .vismaicon-message-bubble,
    .vismaicon-comment {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_comment.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_comment.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/16_comment.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_comment.svg');
        }
    }

    .vismaicon-chart-area {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_chart_type_area.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_chart_type_area.svg');
        }
    }

    .vismaicon-chart-spline-graph {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_chart_type_area_spline_graph.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_chart_type_area_spline_graph.svg');
        }
    }

    .vismaicon-chart-spline-range {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_chart_type_area_spline_range.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_chart_type_area_spline_range.svg');
        }
    }

    .vismaicon-chart-spline {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_chart_type_spline_graph.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_chart_type_spline_graph.svg');
        }
    }

    .vismaicon-chart-bar {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_chart_type_bar.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_chart_type_bar.svg');
        }
    }

    .vismaicon-chart-column {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_chart_type_column.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_chart_type_column.svg');
        }
    }

    .vismaicon-chart-graph {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_chart_type_graph.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_chart_type_graph.svg');
        }
    }

    .vismaicon-chart-heat-map {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_chart_type_heat_map.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_chart_type_heat_map.svg');
        }
    }

    .vismaicon-chart-line {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_chart_type_line.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_chart_type_line.svg');
        }
    }

    .vismaicon-chart-number {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_chart_type_number.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_chart_type_number.svg');
        }
    }

    .vismaicon-chart-pie {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_chart_type_pie.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_chart_type_pie.svg');
        }
    }

    .vismaicon-chart-polar {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_chart_type_polar.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_chart_type_polar.svg');
        }
    }

    .vismaicon-chart-sparklines {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_chart_type_sparklines.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_chart_type_sparklines.svg');
        }
    }

    .vismaicon-chart-speedometer {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_chart_type_speedometer.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_chart_type_speedometer.svg');
        }
    }

    .vismaicon-chart-stacked {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_chart_type_stacked.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_chart_type_stacked.svg');
        }
    }

    .vismaicon-chart-table {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_chart_type_table.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_chart_type_table.svg');
        }
    }

    .vismaicon-chart-traffic-light {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_chart_type_traffic_light.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_chart_type_traffic_light.svg');
        }
    }

    .vismaicon-cloud {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_cloud.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_cloud.svg');
        }
    }

    .vismaicon-color {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_color.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_color.svg');
        }
    }

    .vismaicon-combogrid {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_combogrid.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_combogrid.svg');
        }
    }

    .vismaicon-compare {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_compare.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_compare.svg');
        }
    }

    .vismaicon-confluence {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_confluence.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_confluence.svg');
        }
    }

    .vismaicon-feedback {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_feedback.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_feedback.svg');
        }
    }

    .vismaicon-contact-information {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_contact_information.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_contact_information.svg');
        }
    }

    .vismaicon-content-edit {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_content_edit.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_content_edit.svg');
        }
    }

    .vismaicon-contract-management {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_contract_management.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_contract_management.svg');
        }
    }

    .vismaicon-create-order {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_create_order.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_create_order.svg');
        }
    }

    .vismaicon-comment-order {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_comment_order.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_comment_order.svg');
        }
    }

    .vismaicon-construction {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_construction.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_construction.svg');
        }
    }

    .vismaicon-csv {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_csv.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_csv.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/16_csv.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_csv.svg');
        }
    }

    .vismaicon-cost-request {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_cost_request.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_cost_request.svg');
        }
    }

    .vismaicon-currency {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_currency.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_currency.svg');
        }
    }

    .vismaicon-custom-views {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_custom_views.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_custom_views.svg');
        }
    }

    .vismaicon-cut {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_cut.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_cut.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/16_cut.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_cut.svg');
        }
    }
    
    .vismaicon-copy {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_copy.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_copy.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/16_copy.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_copy.svg');
        }
    }

    .vismaicon-daily-allowance {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_daily_allowance.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_daily_allowance.svg');
        }
    }
    
    .vismaicon-date-picker {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_date_picker.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_date_picker.svg');
        }
    }

    .vismaicon-database {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_database.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_database.svg');
        }
    }

    .vismaicon-delivery {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_delivery.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_delivery.svg');
        }
    }

    .vismaicon-delete {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_delete.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_delete.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/16_delete.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_delete.svg');
        }
    }

    .vismaicon-delivery-notification {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_delivery_notification.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_delivery_notification.svg');
        }
    }

    .vismaicon-delivery-reception {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_delivery_reception.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_delivery_reception.svg');
        }
    }

    .vismaicon-delivery-reception-1 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_delivery_reception_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_delivery_reception_1.svg');
        }
    }

    .vismaicon-design-model {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_design_model.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_design_model.svg');
        }
    }

    .vismaicon-distribution {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_distribution.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_distribution.svg');
        }
    }

    .vismaicon-documents-area {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_documents_area.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_documents_area.svg');
        }
    }

    .vismaicon-document-info-1 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_document_information.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_document_information.svg');
        }
    }

    .vismaicon-edit {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_edit.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_edit.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/16_edit.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_edit.svg');
        }
    }

    .vismaicon-environment {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_environment.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_environment.svg');
        }
    }

    .vismaicon-exclude,
    .vismaicon-extrude {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_exclude.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_exclude.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/16_exclude.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_exclude.svg');
        }
    }

    .vismaicon-expense {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_expense.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_expense.svg');
        }
    }

    .vismaicon-expense-1 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_expense_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_expense_1.svg');
        }
    }

    .vismaicon-expense-2 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_expense_2.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_expense_2.svg');
        }
    }

    .vismaicon-expense-3 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_expense_3.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_expense_3.svg');
        }
    }

    .vismaicon-expense-4 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_expense_4.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_expense_4.svg');
        }
    }

    .vismaicon-export-to-salary {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_export_to_salary.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_export_to_salary.svg');
        }
    }
        
    .vismaicon-flag {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_flag.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_flag.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/16_flag.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_flag.svg');
        }
    }

    .vismaicon-filter-column,
    .vismaicon-filter-1 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_filter_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_filter_1.svg');
        }
    }

    .vismaicon-filter-reset {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_filter_reset.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_filter_reset.svg');
        }
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/16_filter_reset.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_filter_reset.svg');
        }
    }

    .vismaicon-finance {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_finance.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_finance.svg');
        }
    }

    .vismaicon-flash {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_flash.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_flash.svg');
        }
    }

    .vismaicon-flex-time { 
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_flex_time.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_flex_time.svg');
        }
    }

    .vismaicon-forklift {
        &:before {  
            mask-image: url('#{$vud-icons-path}/worksurface/24_forklift.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_forklift.svg');
        }
    }

    .vismaicon-forward-email {
        &:before {  
            mask-image: url('#{$vud-icons-path}/worksurface/24_forward_email.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_forward_email.svg');
        }
    }

    .vismaicon-future {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_future.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_future.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/16_future.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_future.svg');
        }
    }

    .vismaicon-google-drive {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_google_drive.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_google_drive.svg');
        }
    }

    .vismaicon-hand-holding-box {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_hand_holding_box.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_hand_holding_box.svg');
        }
    }

    .vismaicon-headset {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_headset.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_headset.svg');
        }
    }

    .vismaicon-heartbeat-filled {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_heartbeat_filled.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_heartbeat_filled.svg');
        }
    }

    .vismaicon-heartbeat {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_heartbeat.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_heartbeat.svg');
        }
    }

    .vismaicon-heart {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_heart.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_heart.svg');
        }
    }

    .vismaicon-heavy-weight { 
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_heavy_weight.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_heavy_weight.svg'); 
        }
    }

    .vismaicon-heavy-weight-1 { 
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_heavy_weight_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_heavy_weight_1.svg');
        }
    }

    .vismaicon-hierarchy {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_hierarchy.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_hierarchy.svg');
        }
    }

    .vismaicon-history {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_history.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_history.svg');
        }
    }

    .vismaicon-holiday {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_holiday.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_holiday.svg');
        }
    }

    .vismaicon-holiday-1 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_holiday_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_holiday_1.svg');
        }
    }

    .vismaicon-import-company {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_import_company.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_import_company.svg');
        }
    }

    .vismaicon-import-user {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_import_user.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_import_user.svg');
        }
    }

    .vismaicon-incoming {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_incoming.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_incoming.svg');
        }
    }

    .vismaicon-integration {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_integration.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_integration.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/16_integration.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_integration.svg');
        }
    }

    .vismaicon-incoming-email {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_incoming_email.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_incoming_email.svg');
        }
    }

    .vismaicon-invoice {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_invoice.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_invoice.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/16_invoice.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_invoice.svg');
        }
    }

    .vismaicon-invoice-1 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_invoice_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_invoice_1.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/16_invoice_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_invoice_1.svg');
        }
    }

    .vismaicon-kpi {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_kpi.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_kpi.svg');
        }
    }

    .vismaicon-kid-under-3 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_kid_under_3.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_kid_under_3.svg');
        }
    }

    .vismaicon-kid-under-5 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_kid_under_5.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_kid_under_5.svg');
        }
    }

    .vismaicon-kindergarten {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_kindergarten.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_kindergarten.svg');
        }
    }

    .vismaicon-laptop {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_laptop.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_laptop.svg');
        }
    }

    .vismaicon-layout-templates {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_layout_templates.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_layout_templates.svg');
        }
    }

    .vismaicon-leave-request {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_leave_request.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_leave_request.svg');
        }
    }

    .vismaicon-lightbulb {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_lightbulb.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_lightbulb.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/16_lightbulb.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_lightbulb.svg');
        }
    }

    .vismaicon-letter {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_letter.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_letter.svg');
        }
    }

    .vismaicon-letter-1 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_letter_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_letter_2.svg');
        }
    }

    .vismaicon-letter-2 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_letter_2.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_letter_2.svg');
        }
    }

    .vismaicon-letter-3 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_letter_3.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_letter_3.svg');
        }
    }

    .vismaicon-letter-4 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_letter_4.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_letter_4.svg');
        }
    }

    .vismaicon-lodging {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_lodging.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_lodging.svg');
        }
    }

    .vismaicon-lodging-1 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_lodging_2.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_lodging_2.svg');
        }
    }

    .vismaicon-managers-time-registration { 
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_managers_time_registration.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_managers_time_registration.svg');
        }
    }

    .vismaicon-merge {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_merge.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_merge.svg');
        }
    }

    .vismaicon-narrow {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_narrow.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_narrow.svg');
        }
    }

    .vismaicon-new {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_new.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_new.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/16_new.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_new.svg');
        }
    }

    .vismaicon-multiple-selection {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_multiple_selection.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_multiple_selection.svg');
        }
    }

    .vismaicon-order-imported {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_order_imported.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_order_imported.svg');
        }
    }

    .vismaicon-order-closed {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_order_closed.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_order_closed.svg');
        }
    }

    .vismaicon-organisation {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_organisation.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_organisation.svg');
        }
    }

    .vismaicon-new-folder {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_new_folder.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_new_folder.svg');
        }
    }

    .vismaicon-new-links {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_new_links.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_new_links.svg');
        }
    }

    .vismaicon-new-request {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_new_request.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_new_request.svg');
        }
    }

    .vismaicon-overtime {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_overtime.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_overtime.svg');
        }
    }

    .vismaicon-paperplane {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_paperplane.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_paperplane.svg');
        }
    }

    .vismaicon-parental-leave {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_parental_leave.svg'); 
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_parental_leave.svg');
        }
    }

    .vismaicon-parental-leave-2 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_parental_leave_2.svg'); 
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_parental_leave_2.svg');
        }
    }

    .vismaicon-paste {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_paste.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_paste.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/16_paste.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_paste.svg');
        }
    }

    .vismaicon-print {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_print.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_print.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/16_print.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_print.svg');
        }
    }

    .vismaicon-row-level-security {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_row_level_security.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_row_level_security.svg');
        }
    }

    .vismaicon-redo {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_redo.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_redo.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/16_redo.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_redo.svg'); 
        }
    }

    .vismaicon-refresh {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_refresh.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_refresh.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/16_refresh.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_refresh.svg');
        }
    }

    .vismaicon-reminder {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_reminder.svg');
            mask-image: url('#{$vud-icons-path}/worksurface/24_reminder.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_reminder.svg');
            mask-image: url('#{$vud-icons-path}/worksurface/16_reminder.svg'); 
        }
    }

    .vismaicon-report {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_report.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_report.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/16_report.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_report.svg');
        }
    }
    
    .vismaicon-report-1 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_report_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_report_1.svg');
        }
    }
    
    .vismaicon-report-2 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_report_2.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_report_2.svg');
        }
    }
    
    .vismaicon-report-3 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_report_3.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_report_3.svg');
        }
    }
    
    .vismaicon-reset-page {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_reset_page.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_reset_page.svg');
        }
    }
    
    .vismaicon-reset-page-1 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_reset_page_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_reset_page_1.svg');
        }
    }
    
    .vismaicon-restrict {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_restrict.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_restrict.svg');
        }
    }
    
    .vismaicon-restrict-1 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_restrict_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_restrict_1.svg');
        }
    }

    .vismaicon-salary {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_salary.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_salary.svg');
        }
    }
    
    .vismaicon-save-and-start-new {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_save_and_start_new.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_save_and_start_new.svg');
        }
    }
    
    .vismaicon-save-and-use-template {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_save_and_use_template.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_save_and_use_template.svg');
        }
    }

    .vismaicon-scanner {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_scanner.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_scanner.svg');
        }
    }

    .vismaicon-schedule {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_schedule.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_schedule.svg');
        }
    }

    .vismaicon-sick-child {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_sick_child.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_sick_child.svg');
        }
    }

    .vismaicon-sickness {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_sickness.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_sickness.svg');
        }
    }

    .vismaicon-single-selection {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_single_selection.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_single_selection.svg');
        }
    }

    .vismaicon-ship {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_ship.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_ship.svg');
        }
    }

    .vismaicon-shipping {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_shipping.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_shipping.svg');
        }
    }

    .vismaicon-shortage-surplus {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_shortage_surplus.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_shortage_surplus.svg');
        }
    }

    .vismaicon-special-approved {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_special_approved.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_special_approved.svg');
        }
    }

    .vismaicon-special-day {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_special_day.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_special_day.svg');
        }
    }

    .vismaicon-special-day-1 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_special_day_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_special_day_1.svg');
        }
    }

    .vismaicon-split {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_split.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_split.svg');
        }
    }

    .vismaicon-storage-1 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_storage_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_storage_1.svg');
        }
    }

    .vismaicon-substitute-management {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_substitute_management.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_substitute_management.svg');
        }
    }

    .vismaicon-subway {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_subway.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_subway.svg');
        }
    }

    .vismaicon-summary {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_summary.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_summary.svg');
        }
    }

    .vismaicon-summing-assignments {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_summing_assignments.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_summing_assignments.svg');
        }
    }

    .vismaicon-system-management {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_system_management.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_system_management.svg');
        }
    }

    .vismaicon-table-cell {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_table_cell.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_table_cell.svg');
        }
    }

    .vismaicon-tag {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_tag.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_tag.svg');
        }
    }

    .vismaicon-tags {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_tags.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_tags.svg');
        }
    }

    .vismaicon-task-overview {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_task_overview.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_task_overview.svg');
        }
    }

    .vismaicon-tie {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_tie.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_tie.svg');
        }
    }

    .vismaicon-time {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_time.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_time.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/16_time.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_time.svg');
        }
    }

    .vismaicon-taxes {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_taxes.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_taxes.svg');
        }
    }

    .vismaicon-text-document {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_text_document.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_text_document.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/16_text_document.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_text_document.svg');
        }
    }

    .vismaicon-timesheet {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_timesheet.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_timesheet.svg');
        }
    }

    .vismaicon-today {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_today.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_today.svg');
        }
    }

    .vismaicon-tram {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_tram.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_tram.svg');
        }
    }

    .vismaicon-travel {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_travel.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_travel.svg');
        }
    }
    
    .vismaicon-trend-normal-down {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_trend_normal_down.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_trend_normal_down.svg');
        }
    }

    .vismaicon-trend-normal-down-1 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_trend_normal_down_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_trend_normal_down_1.svg');
        }
    }
    
    .vismaicon-trend-normal-up {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_trend_normal_up.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_trend_normal_up.svg');
        }
    }

    .vismaicon-trend-normal-up-1 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_trend_normal_up_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_trend_normal_up_1.svg');
        }
    }
    
    .vismaicon-trend-steep-down {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_trend_steep_down.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_trend_steep_down.svg');
        }
    }

    .vismaicon-trend-steep-down-1 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_trend_steep_down_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_trend_steep_down_1.svg');
        }
    }
    
    .vismaicon-trend-steep-up {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_trend_steep_up.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_trend_steep_up.svg');
        }
    }

    .vismaicon-trend-steep-up-1 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_trend_steep_up_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_trend_steep_up_1.svg');
        }
    }

    .vismaicon-truck {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_truck.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_truck.svg');
        }
    }

    .vismaicon-time-report {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_time_report.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_time_report.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/16_time_report.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_time_report.svg');
        }
    }

    .vismaicon-undo {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_undo.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_undo.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/16_undo.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_undo.svg');
        }
    }

    .vismaicon-unlink {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_unlink.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_unlink.svg');
        }
    }

    .vismaicon-under-investigation {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_under_investigation.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_under_investigation.svg');
        }
    }

    .vismaicon-unread-comment {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_unread_comment.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_unread_comment.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/16_unread_comment.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_unread_comment.svg');
        }
    }

    .vismaicon-user-connection {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_user_connection.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_user_connection.svg');
        }
    }

    .vismaicon-user-documents {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_user_documents.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_user_documents.svg');
        }
    }

    .vismaicon-user-list {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_user_list.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_user_list.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/16_user_list.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_user_list.svg');
        }
    }

    .vismaicon-user-list-1 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_user_list_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_user_list_1.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/16_user_list_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_user_list_1.svg');
        }
    }

    .vismaicon-user-security {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_user_security.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_user_security.svg');
        }
    }

    .vismaicon-vacation {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_vacation.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_vacation.svg');
        }
    }

    .vismaicon-vacation-1 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_vacation_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_vacation_1.svg');
        }
    }

    .vismaicon-vacation-suitcase {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_vacation_suitcase.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_vacation_suitcase.svg');
        }
    }

    .vismaicon-video-meeting {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_video_meeting.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_video_meeting.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/16_video_meeting.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_video_meeting.svg');
        }
    }

    .vismaicon-working-day {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_working_day.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_working_day.svg');
        }
    }

    .vismaicon-view-time-report {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_view_time_report.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_view_time_report.svg');
        }
    }

    .vismaicon-walk {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_walk.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_walk.svg');
        }
    }

    .vismaicon-wide {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_wide.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_wide.svg');
        }
    }
   
    .vismaicon-zoom-in {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_zoom_in.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_zoom_in.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/16_zoom_in.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_zoom_in.svg');
        }
    }

    .vismaicon-zoom-out {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface/24_zoom_out.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_zoom_out.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface/16_zoom_out.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_zoom_out.svg');
        }
    }



.vismaicon-save {
    &:before {
        mask-image: url('#{$vud-icons-path}/worksurface/24_save.svg');
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_save.svg');
    }
    &.vismaicon-sm:before {
        mask-image: url('#{$vud-icons-path}/worksurface/16_save.svg');
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_save.svg');
    }
}


.vismaicon-home {
    &:before {
        mask-image: url('#{$vud-icons-path}/worksurface/24_home.svg');
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_home.svg');
    }
    &.vismaicon-sm:before {
        mask-image: url('#{$vud-icons-path}/worksurface/16_home.svg');
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_home.svg');
    }
}

.vismaicon-employee,
.vismaicon-user {
    &:before {
        mask-image: url('#{$vud-icons-path}/worksurface/24_user.svg');
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_user.svg');
    }
    &.vismaicon-sm:before {
        mask-image: url('#{$vud-icons-path}/worksurface/16_user.svg');
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_user.svg');
    }
}

.vismaicon-employee-1,
.vismaicon-user-1 {
    &:before {
        mask-image: url('#{$vud-icons-path}/worksurface/24_user_1.svg');
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_user_1.svg');
    }
    &.vismaicon-sm:before {
        mask-image: url('#{$vud-icons-path}/worksurface/16_user_1.svg');
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_user_1.svg');
    }
}

.vismaicon-employee-2,
.vismaicon-user-2 {
    &:before {
        mask-image: url('#{$vud-icons-path}/worksurface/24_user_2.svg');
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_user_2.svg');
    }
}

.vismaicon-users {
    &:before {
        mask-image: url('#{$vud-icons-path}/worksurface/24_users.svg');
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_users.svg');
    }
    &.vismaicon-sm:before {
        mask-image: url('#{$vud-icons-path}/worksurface/16_users.svg');
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_users.svg');
    }
}

.vismaicon-time {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_time.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_time.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_time.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/16_time.svg');
    }
}

.vismaicon-search {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_search.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_search.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_search.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/16_search.svg');
    }
}

.vismaicon-search-user {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_search_user.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_search_user.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_search_user.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/16_search_user.svg');
    }
}

.vismaicon-note {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_note.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_note.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_note.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/16_note.svg');
    }
}

.vismaicon-graphs {
    &:before { 
        mask-image: url('#{$vud-icons-path}/worksurface/24_graphs.svg');
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_graphs.svg');
    }
    &.vismaicon-sm:before { 
        mask-image: url('#{$vud-icons-path}/worksurface/16_graphs.svg');
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_graphs.svg');
    }
}

.vismaicon-email {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_email.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_email.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_email.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/16_email.svg');
    }
}

.vismaicon-phone {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_phone.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_phone.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_phone.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/16_phone.svg');
    }
}

.vismaicon-lock,
.vismaicon-locked {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_locked.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_locked.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_locked.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/16_locked.svg');
    }
}

.vismaicon-unlock,
.vismaicon-unlocked {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_unlocked.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_unlocked.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_unlocked.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/16_unlocked.svg');
    }
}

.vismaicon-unpin {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_unpin.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_unpin.svg');
    }
}

.vismaicon-money {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_money.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_money.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_money.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/16_money.svg');
    }
}

.vismaicon-web {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_web.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_web.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_web.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/16_web.svg');
    }
}

.vismaicon-open {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_open.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_open.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_open.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/16_open.svg');
    }
}

.vismaicon-upload {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_upload.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_upload.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_upload.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/16_upload.svg');
    }
}

.vismaicon-download {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_download.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_download.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_download.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/16_download.svg');
    }
}

.vismaicon-preview {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_preview.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_preview.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_preview.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/16_preview.svg');
    }
}

.vismaicon-preview-off {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_preview_off.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_preview_off.svg');
    }
}

.vismaicon-favourites,
.vismaicon-favourite {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_favourite.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_favourite.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_favourite.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/16_favourite.svg');
    }
}

.vismaicon-like {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_like.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_like.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_like.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/16_like.svg');
    }
}

.vismaicon-dislike {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_dislike.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_dislike.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_dislike.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/16_dislike.svg');
    }
}

.vismaicon-filter {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_filter.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_filter.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_filter.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/16_filter.svg');
    }
}

.vismaicon-word,
.vismaicon-office-word {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_word.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_word.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_word.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/16_word.svg');
    }
}

.vismaicon-powerpoint,
.vismaicon-office-powerpoint {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_powerpoint.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_powerpoint.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_powerpoint.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/16_powerpoint.svg');
    }
}

.vismaicon-excel,
.vismaicon-office-excel {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_excel.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_excel.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_excel.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/16_excel.svg');
    }
}

.vismaicon-export {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_export.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_export.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_export.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/16_export.svg');
    }
}

.vismaicon-pdf {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_pdf.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_pdf.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_pdf.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/16_pdf.svg');
    }
}

.vismaicon-changelog {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_changelog.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_changelog.svg');
    }
}

.vismaicon-replace {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_replace.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_replace.svg');
    }
}

.vismaicon-drag {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_drag.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_drag.svg');
    }
}

.vismaicon-image-document {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_image_document.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_image_document.svg');
    }
}

.vismaicon-xml-document {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_xml_document.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_xml_document.svg');
    }
}

.vismaicon-add-user {
    &:before { 
        -webkit-mask-image:  url('#{$vud-icons-path}/worksurface/24_add_user.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_add_user.svg');
    }
    &.vismaicon-sm:before { 
        -webkit-mask-image:  url('#{$vud-icons-path}/worksurface/16_add_user.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/16_add_user.svg');
    }
}

.vismaicon-alert {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_alert.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_alert.svg');
    }
}

.vismaicon-announcement {
    &:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_announcement.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_announcement.svg');
    }
    &.vismaicon-sm:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_announcement.svg');
    mask-image: url('#{$vud-icons-path}/worksurface/16_announcement.svg');
    }
}

.vismaicon-archive-document {
    &:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_archive_document.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_archive_document.svg');
    }
    &.vismaicon-sm:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_archive_document.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/16_archive_document.svg');
    }
}

.vismaicon-breadcrumb-folder {
    &:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_breadcrumb_folder.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_breadcrumb_folder.svg');
    }
}

.vismaicon-contract {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_contract.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_contract.svg');
    }
}

.vismaicon-database {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_database.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_database.svg');
    }
}

.vismaicon-detach {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_detach.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_detach.svg');
    }
}

.vismaicon-document-info {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_document_info.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_document_info.svg');
    }
}

.vismaicon-education {
    &:before {
        mask-image: url('#{$vud-icons-path}/worksurface/24_education.svg');
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_education.svg');
    }
    &.vismaicon-sm:before { 
        mask-image: url('#{$vud-icons-path}/worksurface/16_education.svg');
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_education.svg');
    }
}

.vismaicon-encrypted-pdf {
    &:before {
        mask-image: url('#{$vud-icons-path}/worksurface/24_encrypted_pdf.svg');
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_encrypted_pdf.svg');
    }
}

.vismaicon-enter-full-screen {
    &:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_enter_full_screen.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_enter_full_screen.svg');
    }
    &.vismaicon-sm:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_enter_full_screen.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/16_enter_full_screen.svg');
    }
}

.vismaicon-exit-full-screen {
    &:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_exit_full_screen.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_exit_full_screen.svg');
    }
    &.vismaicon-sm:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_exit_full_screen.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/16_exit_full_screen.svg');
    }
}

.vismaicon-evaluate {
    &:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_evaluate.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_evaluate.svg');
    }
}

.vismaicon-events {
    &:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_events.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_events.svg');
    }
}

.vismaicon-exclude {
    &:before { 
        -webkit-mask-image:  url('#{$vud-icons-path}/worksurface/24_exclude.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_exclude.svg');
    }
    &.vismaicon-sm:before { 
        -webkit-mask-image:  url('#{$vud-icons-path}/worksurface/16_exclude.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/16_exclude.svg');
    }
}

.vismaicon-folder-building {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_folder_building.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_folder_building.svg');
    }
}

.vismaicon-hourglass {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_hourglass.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_hourglass.svg');
    }
}

.vismaicon-hourglass-1 {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_hourglass_1.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_hourglass_1.svg');
    }
}

.vismaicon-hourglass-2 {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_hourglass_2.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_hourglass_2.svg');
    }
}

.vismaicon-hourglass-3 {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_hourglass_3.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_hourglass_3.svg');
    }
}

.vismaicon-link {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_link.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_link.svg');
    }
}

.vismaicon-location {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_location.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_location.svg');
    }
}

.vismaicon-key,
.vismaicon-logon-failed {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_logon_failed.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_logon_failed.svg');
    }
}

.vismaicon-lunch {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_lunch.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_lunch.svg');
    }
}

.vismaicon-note-blank {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_note_blank.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_note_blank.svg');
    }
}

.vismaicon-note-filled {
    &:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_note_filled.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_note_filled.svg');
    }
    &.vismaicon-sm:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_note_filled.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/16_note_filled.svg');
    }
}

.vismaicon-office-building {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_office_building.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_office_building.svg');
    }
}

.vismaicon-panel {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_panel.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_panel.svg');
    }
}

.vismaicon-project {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_project.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_project.svg');
    }
}

.vismaicon-price {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_price.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_price.svg');
    }
}

.vismaicon-qr-code {
    &:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_qr_code.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_qr_code.svg');
    }
}

.vismaicon-reference {
    &:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_reference.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_reference.svg');
    }
}

.vismaicon-remarks {
    &:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_remarks.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_remarks.svg');
    }
}

.vismaicon-remove-selection {
    &:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_remove_selection.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_remove_selection.svg');
    }
}

.vismaicon-remove-user {
    &:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_remove_user.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_remove_user.svg');
    }
}

.vismaicon-rotate-ccw {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_rotate_ccw.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_rotate_ccw.svg');
    }
}

.vismaicon-rotate-cw {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_rotate_cw.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_rotate_cw.svg');
    }
}

.vismaicon-send {
    &:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_send.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_send.svg');
    }
    &.vismaicon-sm:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_send.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/16_send.svg');
    }
}

.vismaicon-sending-failed {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_sending_failed.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_sending_failed.svg');
    }
    &.vismaicon-sm:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_send.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/16_send.svg');
    }
}

// .vismaicon-share-1 {
//     &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_share_1.svg');
//         mask-image: url('#{$vud-icons-path}/worksurface/24_share_1.svg');
//     }
// }

.vismaicon-share {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_share.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_share.svg');
    }
}

.vismaicon-shared {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_shared_with_me.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_shared_with_me.svg');
    }
}

.vismaicon-shared-user {
    &:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_shared_user.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_shared_user.svg');
    }
}

.vismaicon-shift-market {
    &:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_shift_market.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_shift_market.svg');
    }
}

.vismaicon-sound {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_sound.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_sound.svg');
    }
}

.vismaicon-text-document {
    &:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_text_document.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_text_document.svg');
    }
    &.vismaicon-sm:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_text_document.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/16_text_document.svg');
    }
}

.vismaicon-undefined-document {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_undefined_document.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_undefined_document.svg');
    }
}

.vismaicon-user-folder {
    &:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_user_folder.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_user_folder.svg');
    }

    &.vismaicon-sm:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_user_folder.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/16_user_folder.svg');
    }
}

.vismaicon-user-placeholder {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_user_placeholder.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_user_placeholder.svg');
    }
}

.vismaicon-video {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_video.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_video.svg');
    }
}

.vismaicon-xml {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_xml.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_xml.svg');
    }
}

.vismaicon-agreement {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_agreement.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_agreement.svg');
    }
}

.vismaicon-change-owner {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_change_owner.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_change_owner.svg');
    }
}

.vismaicon-break,
.vismaicon-coffee-break {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_coffee_break.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_coffee_break.svg');
    }
}

.vismaicon-comment-chat {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_comment_chat.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_comment_chat.svg');
    }
}

.vismaicon-comment-community {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_comment_community.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_comment_community.svg');
    }
}

.vismaicon-comment-web-reply {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_comment_web_reply.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_comment_web_reply.svg');
    }
}

.vismaicon-jira {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_jira.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_jira.svg');
    }
}

.vismaicon-list-view {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_list_view.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_list_view.svg');
    }
}

.vismaicon-list-view-1 {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_list_view_1.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_list_view_1.svg');
    }
}

.vismaicon-platform-cloud {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_platform_cloud.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_platform_cloud.svg');
    }
}


.vismaicon-platform-mobile {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_platform_mobile.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_platform_mobile.svg');
    }
}

.vismaicon-sort-list {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_sort_list.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_sort_list.svg');
    }
}

.vismaicon-storage {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_storage.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_storage.svg');
    }
}

.vismaicon-tile-view {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_tile_view.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_tile_view.svg');
    }
}

.vismaicon-help {
    &:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_help.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_help.svg');
    }
    &.vismaicon-sm:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_help.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/16_help.svg');
    } 
}

.vismaicon-alarm {
    &:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_alarm.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_alarm.svg');
    }
    &.vismaicon-sm:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_alarm.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/16_alarm.svg');
    }
}

.vismaicon-calendar {
    &:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_calendar.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_calendar.svg');
    }
}

.vismaicon-docs,
.vismaicon-document-production,
.vismaicon-document_production {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_document_production.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_document_production.svg');
    }
}

.vismaicon-info {
    &:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_info.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_info.svg');
    }
    &.vismaicon-sm:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_information.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/16_information.svg');
    }
}

.vismaicon-information-link {
    &:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_information_link.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_information_link.svg');
    }
    &.vismaicon-sm:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_information_link.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/16_information_link.svg');
    }
}

.vismaicon-library {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_library.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_library.svg');
    }
}

.vismaicon-logout {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_logout.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_logout.svg');
    }
}

.vismaicon-pin {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_pin.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_pin.svg');
    }
}

.vismaicon-settings {
    &:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_settings.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_settings.svg');
    }
    &.vismaicon-sm:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/worksurface/16_settings.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/16_settings.svg');
    }
}

.vismaicon-shopping-cart,
.vismaicon-shopping_cart {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_shopping_cart.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_shopping_cart.svg');
    }
}

.vismaicon-tools {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_tools.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_tools.svg');
    }
}

.vismaicon-user-settings,
.vismaicon-user_settings {
    &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface/24_user_settings.svg');
        mask-image: url('#{$vud-icons-path}/worksurface/24_user_settings.svg');
    }
}


/**** Interface controls icons ****/
.vismaicon-datepicker {
    &:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/interface-controls/datepicker.svg');
        mask-image: url('#{$vud-icons-path}/interface-controls/datepicker.svg');
    }
}

.vismaicon-timepicker {
    &:before { 
        -webkit-mask-image: url('#{$vud-icons-path}/interface-controls/timepicker.svg');
        mask-image: url('#{$vud-icons-path}/interface-controls/timepicker.svg');
    }
}

.vismaicon-autocomplete {
    &:before {
        -webkit-mask-image: url('#{$vud-icons-path}/interface-controls/autocomplete.svg');
        mask-image: url('#{$vud-icons-path}/interface-controls/autocomplete.svg');
    }
}

.vismaicon-multiselection {
    &:before {
        -webkit-mask-image: url('#{$vud-icons-path}/interface-controls/multiselection.svg');
        mask-image: url('#{$vud-icons-path}/interface-controls/multiselection.svg');
    }
}

.vismaicon-close {
    &:before {
        -webkit-mask-image: url('#{$vud-icons-path}/interface-controls-dynamic/close.svg');
        mask-image: url('#{$vud-icons-path}/interface-controls-dynamic/close.svg');
    }
}

//# Pictograms
//=== Pictogram plus (128x128px)
.visma-pictogram-plus {
    &.visma-pictogram-plus-add-invoice {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_add_invoice.svg');
    }

    &.visma-pictogram-plus-air-baloon {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_air_baloon.svg');
    }

    &.visma-pictogram-plus-binary-code {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_binary_code.svg');
    }

    &.visma-pictogram-plus-calendar {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_calendar.svg');
    }

    &.visma-pictogram-plus-car-select {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_car_select.svg');
    }

    &.visma-pictogram-plus-chat-bot {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_chat_bot.svg');
    }

    &.visma-pictogram-plus-chat-bubbles {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_chat_bubbles.svg');
    }

    &.visma-pictogram-plus-checklist-edit {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_checklist_edit.svg');
    }

    &.visma-pictogram-plus-code-language {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_code_language.svg');
    }

    &.visma-pictogram-plus-community {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_community.svg');
    }

    &.visma-pictogram-plus-dedicated {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_dedicated.svg');
    }

    &.visma-pictogram-plus-education {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_education.svg');
    }

    &.visma-pictogram-plus-entrerprenorial-user {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_entrerprenorial_user.svg');
    }

    &.visma-pictogram-plus-faq-bubbles {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_faq_bubbles.svg');
    }

    &.visma-pictogram-plus-favorite-user {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_favorite_user.svg');
    }

    &.visma-pictogram-plus-file {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_file.svg');
    }

    &.visma-pictogram-plus-file-checked {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_file_checked.svg');
    }

    &.visma-pictogram-plus-file-settings {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_file_settings.svg');
    }

    &.visma-pictogram-plus-gift {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_gift.svg');
    }

    &.visma-pictogram-plus-global-location {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_global_location.svg');
    }

    &.visma-pictogram-plus-graduate-user {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_graduate_user.svg');
    }

    &.visma-pictogram-plus-group-chat {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_group_chat.svg');
    }

    &.visma-pictogram-plus-hand-interactive {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_hand_interactive.svg');
    }

    &.visma-pictogram-plus-help-client {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_help_client.svg');
    }

    &.visma-pictogram-plus-idea-creativity {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_idea_creativity.svg');
    }

    &.visma-pictogram-plus-idea-creativity-2 {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_idea_creativity_2.svg');
    }

    &.visma-pictogram-plus-job-search {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_job_search.svg');
    }

    &.visma-pictogram-plus-key-user {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_key_user.svg');
    }

    &.visma-pictogram-plus-laptop {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_laptop.svg');
    }

    &.visma-pictogram-plus-laptop-statistics {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_laptop_statistics.svg');
    }

    &.visma-pictogram-plus-map-location {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_map_location.svg');
    }

    &.visma-pictogram-plus-mileage-expense {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_mileage_expense.svg');
    }

    &.visma-pictogram-plus-missing-file {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_missing_file.svg');
    }

    &.visma-pictogram-plus-missing-page {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_missing_page.svg');
    }

    &.visma-pictogram-plus-music-player {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_music_player.svg');
    }

    &.visma-pictogram-plus-new-milestone {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_new_milestone.svg');
    }

    &.visma-pictogram-plus-newspapper {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_newspapper.svg');
    }

    &.visma-pictogram-plus-paid-invoice {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_paid_invoice.svg');
    }

    &.visma-pictogram-plus-payment-sent {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_payment_sent.svg');
    }

    &.visma-pictogram-plus-phone-hand {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_phone_hand.svg');
    }

    &.visma-pictogram-plus-rocket {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_rocket.svg');
    }

    &.visma-pictogram-plus-research {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_research.svg');
    }

    &.visma-pictogram-plus-responsible {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_responsible.svg');
    }

    &.visma-pictogram-plus-scanned-receipt {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_scanned_receipt.svg');
    }

    &.visma-pictogram-plus-search-file {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_search_file.svg');
    }

    &.visma-pictogram-plus-selected-invoice {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_selected_invoice.svg');
    }

    &.visma-pictogram-plus-space-location {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_space_location.svg');
    }

    &.visma-pictogram-plus-statistics {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_statistics.svg');
    }

    &.visma-pictogram-plus-teamwork {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_teamwork.svg');
    }

    &.visma-pictogram-plus-time-expense {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_time_expense.svg');
    }

    &.visma-pictogram-plus-user-checked {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_user_checked.svg');
    }

    &.visma-pictogram-plus-user-love {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_user_love.svg');
    }

    &.visma-pictogram-plus-user-protected {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_user_protected.svg');
    }

    &.visma-pictogram-plus-user-search {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_user_search.svg');
    }

    &.visma-pictogram-plus-user-switch {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_user_switch.svg');
    }

    &.visma-pictogram-plus-video {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_video.svg');
    }

    &.visma-pictogram-plus-work-from-home {
        background-image: url('#{$vud-pictograms-path}/pictograms-plus/pictogram_plus_work_from_home.svg');
    }
}

